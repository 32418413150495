$base-class: ".product-view-description";
$model-tab-child-class: ".product-tabs-table";
$widget-title-text-class: ".widget-title-text";

#{$base-class} {
  &__tabs {
    background: $color-athens-gray-2;
  }

  &__tab-link {
    color: $color-picton-blue;
    background: $color-athens-gray-2;
    font-weight: map-get($font-weight, normal);
    transition: $medium-transition;

    &:before {
      content: "//";
      top: 0;
      left: 0;
    }
  }

  &__tab {
    &--active {
      #{$base-class}__tab-link {
        background: $color-picton-blue;
        color: $color-white;
      }
    }

    &:hover {
      #{$base-class}__tab-link {
        background: $color-picton-blue;
        color: $color-white;
        transition: $medium-transition;
      }
    }
  }

  &__content {
    padding: 2em 0;

    .gui-container.wysiwyg {
      > * {
        padding: 0 px-to-rem(10px);
        @include breakpoint($breakpoint-switch) {
          padding: 0 0;
        }
      }
    }

    @include breakpoint($breakpoint-switch) {
      padding: 2em 0;
    }
  }

  #{$model-tab-child-class}__title-wrapper.main-title--with-decoration {
    #{$model-tab-child-class}__title {
      @extend %title-for-product-page-tabs;
    }
  }

  #{$model-tab-child-class}__title,
  #{$widget-title-text-class} {
    .main-title__title {
      @extend %title-for-product-page-tabs;
    }
  }
}
