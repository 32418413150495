// ## Containers
$container-max-width-extra-small: 850px;
$container-max-width-small: 1070px;
$container-max-width-medium: 1280px;
$container-max-width-large: 1600px;

$container-steps-max-width: 970px;

$container-x-padding-mobile: 20px;
$container-x-padding-tablet: 30px;
$container-x-padding-desktop: 40px;
$container-x-padding-large-desktop: 50px;

// ## Font-sizes
$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-m: 16px;
$font-size-l: 18px;
$font-size-xl: 20px;
$font-size-xxl: 25px;
$font-size-xxxl: 30px;

// ## Gap
$gap-xxxs: 5px;
$gap-xxs: 10px;
$gap-xs: 15px;
$gap-s: 20px;
$gap-m: 30px;
$gap-l: 40px;
$gap-xl: 50px;
$gap-xxl: 60px;
$gap-xxxl: 100px;
$gap-xxxxl: 160px;
