.blog-article-index {
  .column.main > :not([class*="widget"]) {
    margin: 0 auto;
    width: 100%;

    @include breakpoint($breakpoint-switch) {
      width: 96%;
      max-width: $gui-container-max-width;
    }
  }
}
