%base-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: $base-btn-vertical-padding $base-btn-horizontal-padding;
  border: 0;
  color: inherit;
  font-family: $font-default;
  font-size: inherit;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  background: none;
  height: 50px;
  border-radius: 25px;
  line-height: 50px;
  transition: $medium-transition;
}

%base-btn--default {
  @include btn-style(
    $color: $color-default,
    $background-color: $color-default-background
  );
}

%base-btn--primary {
  @include btn-style($background-color: $color-primary, $color: $color-white);
}

%base-btn--inverted {
  @include btn-style($background-color: $color-white, $color: $color-primary);
}

%base-btn--secondary {
  @include btn-style(
    $background-color: $color-secondary,
    $color: $color-secondary-invert
  );
}

%base-btn--tertiary {
  @include btn-style(
    $background-color: $color-thunderbird,
    $color: $color-white
  );
}

%base-btn--block {
  display: flex;
  width: 100%;
}

%base-btn--with-icon {
  svg {
    margin-left: px-to-em(10px, $base-font-size);
  }
}

%base-btn--discreet {
  // You should also reset properties you've used in %base-btn to avoid side-effects
  font: inherit;
  background: none;
  padding: 0;
  display: inline;
  border: 0;
  color: inherit;
  text-align: inherit;
  text-decoration: inherit;
  border-radius: 0;
}

%base-btn--link {
  padding-left: 0;
  padding-right: 0;
  background: none;
  font-weight: inherit;
  min-height: auto;
  // Add your custom styles here

  &#{$base-btn-block-selector}--hovered,
  &:hover {
    // Add your custom styles here
  }

  &:disabled,
  &[disabled],
  &#{$base-btn-block-selector}--disabled {
    // Add your custom styles here
  }
}

%base-btn--disabled {
  cursor: not-allowed;
}

%base-btn--icon {
  &::before {
    @extend %icon;
    display: block;
    color: currentColor;
    flex: none;
    margin-right: 1em;
  }
}
