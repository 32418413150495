.banner-text-tabs {
  $this: &;
  width: 100%;
  min-height: px-to-em(300px);
  position: relative;

  &__container {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    @include breakpoint($breakpoint-xs) {
      padding: 0 px-to-em($gap-m);
    }
    @include breakpoint($breakpoint-tablet) {
      padding: px-to-em(66px) px-to-em($gap-m);
    }
  }

  &__img {
    height: 100%;
    object-fit: cover;
    display: none;
    position: absolute;
    @include breakpoint($breakpoint-switch) {
      display: block;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    justify-content: space-between;
    @include breakpoint($breakpoint-xxs) {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &__title {
    display: block;
    width: 100%;
    line-height: 40px;
  }

  &__content {
    @extend %base-text;

    opacity: 0;
    display: none;
    z-index: 2;
    width: 100%;
    @include breakpoint($breakpoint-switch) {
      padding-right: 3rem;
    }
  }

  &__keyword {
    position: relative;
    margin-right: px-to-em(20px);
  }

  &__checkbox {
    display: none;

    + #{$this}__label {
      cursor: pointer;
    }
  }

  &__label {
    @extend %link-hover-animation;

    position: relative;
    z-index: 5;

    @include breakpoint($breakpoint-xxs) {
      margin-right: px-to-em(30px, $font-size-m);
    }
  }

  #keyword1:checked ~ #text1,
  #keyword2:checked ~ #text2,
  #keyword3:checked ~ #text3,
  #keyword4:checked ~ #text4,
  #keyword5:checked ~ #text5 {
    opacity: 1;
    display: block;
  }
}
