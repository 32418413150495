.banner-keywords {
  width: 100%;
  position: relative;

  &__img {
    z-index: 2;
    height: 100%;
    object-fit: cover;
  }

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__left-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  &__title {
    display: block;
    width: fit-content;
  }

  &__keyword {
    display: flex;
    align-items: center;
  }
}
