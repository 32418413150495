.catalog-product-view {
  .gui-container.wysiwyg > :not([class*="widget"]) {
    margin: 0 auto;
    width: 100%;

    @include breakpoint($breakpoint-switch) {
      max-width: $gui-container-max-width;
      padding: 0;
    }
  }

  .widget-title-text {
    margin-top: 0;
    .main-title__title {
      margin-top: 0;
    }
  }
}
