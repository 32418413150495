.gui-container.wysiwyg:has(.technical-details-card) {
  display: flex;
  flex-wrap: wrap;

  .widget-title-text {
    width: 100%;
    margin-top: 0;
    margin-bottom: 1em;
    * {
      margin-top: 0;
    }
  }
}

.technical-details-card {
  display: flex;
  flex-direction: column;
  gap: px-to-rem(10px);
  height: 100%;
  width: 100%;

  &:nth-child(even) {
    margin-left: auto;
  }

  @include breakpoint($breakpoint-l) {
    width: 50%;
    max-width: px-to-rem(500px);
    max-height: px-to-rem(150px);
    flex-direction: row;
    gap: px-to-rem(13px);
    margin-bottom: 2em;
  }

  &__image {
    width: px-to-rem(180px);
    height: px-to-rem(150px);
    object-fit: cover;
  }

  &__informations {
    height: 50%;
    overflow: hidden;
    margin: 0;
    @include breakpoint($breakpoint-l) {
      margin: 0 0 px-to-rem(10px) 0;
    }
  }

  &__wrapper {
    overflow: hidden;
  }

  &__title {
    font-weight: map-get($font-weight, bold);
    display: inline-block;
    margin-bottom: px-to-rem(5px);
  }

  &__content {
    line-height: 1.5;
    margin-bottom: px-to-rem(10px);
  }
}
