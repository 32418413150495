$base-class: ".slider-news";
$swiper-pagination-class: ".swiper-pagination";
$main-title-class: ".main-title";
$news-card-class: ".news-card";

#{$base-class} {
  padding-top: unset;

  &__nav {
    display: none;
    border: none;
    color: $color-primary;
    background: none;
    &::after {
      font-size: px-to-rem(30px);
      font-weight: map-get($font-weight, "bold");
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 2em;
  }

  &__swiper-container {
    pointer-events: auto;
    @include breakpoint($breakpoint-s) {
      pointer-events: none;
    }
    clip-path: unset;
  }

  &__main-title {
    #{$main-title-class} {
      &__title {
        font-size: px-to-rem(40px);
        font-family: $font-default;
        font-weight: map-get($font-weight, "light");
        margin-bottom: 0;
        line-height: 1;
      }
    }
  }

  &__news-card {
    position: relative;
    color: $color-white;

    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 55% 100%);
      background-color: rgba($color-black, 0.2);
    }

    &::after {
      clip-path: polygon(100% 0, 45% 0, 100% 100%);
      background-color: rgba($color-black, 0.2);
    }

    #{$news-card-class} {
      &__img {
        position: relative;
        z-index: 1;
        width: 100%;
        object-fit: cover;
      }

      &__title {
        margin-bottom: 0;
        font-family: $font-secondary;
        font-size: px-to-em(20px);
        font-weight: map-get($font-weight, "medium");
      }

      &__text {
        font-family: $font-default;
        font-size: px-to-em(16px);
        font-weight: map-get($font-weight, "normal");
        text-align: center;
      }

      &__overlay {
        position: absolute;
        z-index: 3;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0 3em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &__content-wrapper {
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1.2em;
        padding: 1em;
      }

      &__link {
        pointer-events: all;
      }
    }
  }

  &__pagination {
    display: flex;
    padding: 1em 0 0 0;
    justify-content: center;
    gap: 0.5em;

    #{$swiper-pagination-class} {
      &__bullet {
        cursor: pointer;
        width: 1em;
        height: 1em;
        padding: 0;
        border-radius: 50%;
        border: none;
        background: $color-primary;
        &:not(.swiper-pagination-bullet-active) {
          background: transparentize($color-primary, 0.5);
        }
      }
    }

    @include breakpoint($breakpoint-s) {
      display: none;
    }
  }
}
