.modal-lang-switcher {
  min-width: px-to-rem(300px);
  max-width: px-to-rem(900px);
  width: 100%;
  margin: 0 auto;
  border-radius: px-to-rem(20px);
  background: $color-white;
  color: $color-black;

  &__wrapper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1151;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparentize($color-black, 0.8);
    padding: 2em;

    &--active {
      display: flex;
    }
  }

  &__header,
  &__content {
    padding: px-to-rem(20px);
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-ghost-grey;
    font-size: px-to-rem(30px);
  }

  &__close {
    background: none;
    border: none;
    color: $color-primary;
    font-size: px-to-rem(20px);
    cursor: pointer;
    display: flex;
    padding: px-to-rem(12px) px-to-rem(6px);
    @include breakpoint($breakpoint-tablet) {
      padding: px-to-rem(6px);
      align-items: center;
    }
    &:hover {
      color: darken($color-primary, 10%);
    }
  }

  &__title {
    font-size: $size-m-intermediate;
    @include breakpoint($breakpoint-tablet) {
      font-size: px-to-rem(30px);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }

  &__group-select {
    display: flex;
    flex-direction: column;
    gap: 1em;

    @include breakpoint($breakpoint-tablet) {
      flex-direction: row;
    }
  }

  &__group-select,
  &__select {
    width: 100%;
  }

  &__button {
    width: fit-content;

    &--disabled,
    &--disabled:hover {
      background: $color-ghost-grey;
      cursor: not-allowed;
    }
  }
}
