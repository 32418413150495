%wysiwyg-block {
  font-family: $font-calibri;
  color: $color-tuatara;
}

%wysiwyg-headings {
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not([class]) {
      margin-bottom: 0;
      font-family: $font-lato;
      color: $color-blue-zodiac;
    }
  }

  h2,
  h3 {
    &:not([class]) {
      font-size: px-to-em(25px);
    }
  }

  h4 {
    &:not([class]) {
      font-size: px-to-em(22px);
    }
  }

  h5 {
    &:not([class]) {
      font-size: px-to-em(20px);
    }
  }

  h6 {
    &:not([class]) {
      font-size: px-to-em(18px);
    }
  }
}

%wysiwyg-links {
  a {
    &:not([class]) {
      color: $color-tuatara;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

%wysiwyg-revert-base {
  p {
    &:not([class]) {
      margin: revert;
    }
  }

  ul {
    &:not([class]) {
      padding: revert;
      list-style: revert;
    }
  }
}
