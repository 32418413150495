.image-text-button {
  width: 100%;
  height: px-to-em(380px);
  position: relative;
  overflow: hidden;

  &__overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    color: white;
    padding: px-to-em($gap-l) px-to-em(20px);

    @include breakpoint($breakpoint-switch) {
      padding: px-to-em($gap-l);
    }
  }

  &__title {
    font-weight: map-get($font-weight, medium);
    position: relative;
    text-align: center;

    @include breakpoint($breakpoint-switch) {
      font-size: 40px;
    }
  }
}
