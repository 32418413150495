%default-link {
  color: $color-default-link;
  text-decoration: underline;

  // Default link styles goes here
  // Do not forget pseudo-classes to handle special states
  //&:hover {
  //}
  //
  //&:focus {
  //}
  //
  //&:active {
  //}
  //
  //&:visited {
  //}
}
