$base-class: ".slider-news";

#{$base-class} {
  @include breakpoint($breakpoint-switch) {
    padding-top: px-to-em($gap-xxxl);
  }

  &__container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__outer-wrapper {
    position: relative;
    width: 100%;
    margin: 0 auto;

    @include breakpoint($breakpoint-switch) {
      max-width: px-to-em(1060px);
    }
  }
  &__swiper-container {
    clip-path: polygon(
      50px 0%,
      100% 0,
      100% calc(100% - 50px),
      calc(100% - 50px) 100%,
      0 100%,
      0 50px
    );
  }

  &__nav {
    top: 50%;
    background-color: $color-athens-gray;

    @include breakpoint($breakpoint-switch) {
      background-color: transparent;
    }
  }
}
