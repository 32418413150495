$base-class: ".input-field";
.input-field {
  flex-direction: row;
  background: $color-white;
  height: auto;
  border-radius: px-to-rem(30px);
  gap: px-to-rem(8px);

  &__input {
    font-size: $font-size-m;
    font-family: $font-default;
    padding: px-to-rem(20px);
    border-radius: px-to-rem(60px);
    flex: 1;

    &:active,
    &:focus {
      outline: 1px solid rgba($color-primary, 0.5);
    }
  }

  &__button {
    font-family: $font-lato;
    font-size: $font-size-m;
    border-radius: px-to-rem(60px);
    position: relative;
    background-color: $color-primary;
    font-weight: unset;
    margin: px-to-rem(5px);

    &--with-icon {
      width: px-to-rem(49px);

      &::before {
        font-size: px-to-rem(25px);
        display: block;
        margin: 0 auto;
      }
    }

    &:hover {
      background-color: darken($color-primary, 5%);
    }
  }

  &--column-on-mobile {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 0;

    #{$base-class} {
      &__input {
        padding-right: 0;
      }

      &__button {
        height: px-to-rem(50px);
        &--newsletter {
          &::before {
            content: "//";
            margin-right: px-to-rem(5px);
          }
        }
      }
    }
  }

  // Affect only "with icon" input-field
  &--can-have-error:not(#{$base-class}--small-height) {
    #{$base-class} {
      &__input {
        &:active,
        &:focus {
          outline: 1px solid rgba($color-caribbean-green, 0.5);
        }
      }
      &__button {
        background-color: $color-caribbean-green;
        &:hover {
          background-color: darken($color-caribbean-green, 5%);
        }
      }
    }
  }

  // Affect only "small height" input-field
  &--small-height {
    #{$base-class} {
      display: block;

      &--column-on-mobile {
        flex-direction: column;
      }

      &__input {
        &:active,
        &:focus {
          outline: 1px solid rgba($color-primary, 0.5);
        }
      }

      &__button {
        position: absolute;
        top: 0;
        right: 0;
        font-size: px-to-rem(30px);
        color: $color-primary;
        background-color: transparent;
        &:hover {
          background-color: darken($color-primary, 5%);
        }
        &::before {
          font-weight: map-get($font-weight, "bold");
        }
      }
    }
  }

  @include breakpoint($breakpoint-s) {
    flex-direction: row;

    &--small-height {
      #{$base-class} {
        &__input {
          padding: px-to-rem(5px) px-to-rem(45px) px-to-rem(5px) px-to-rem(16px);
        }
      }
    }
  }

  @include breakpoint($breakpoint-m) {
    padding: 0;
    // Affect all type of input-field
    &__input {
      padding: px-to-rem(5px) px-to-rem(5px) px-to-rem(5px) px-to-rem(32px);
      height: px-to-rem(60px);
    }

    &__button {
      padding: 1rem 2rem;

      &--with-icon {
        padding: 0;
        width: px-to-rem(50px);
      }
    }

    // Affect only "with icon" input-field
    &--can-have-error:not(#{$base-class}--small-height) {
      #{$base-class} {
        &__button {
          padding: 0 px-to-rem(16px);
        }
      }
    }

    // Affect only "small height" input-field
    &--small-height {
      #{$base-class} {
        &__input {
          padding: px-to-rem(5px) px-to-rem(45px) px-to-rem(5px) px-to-rem(32px);
        }

        &__button {
          padding: 0 px-to-rem(8px);
        }
      }
    }
  }
}
