.store-locator-large {
  z-index: 1;

  &__stores-item {
    border: 1px solid $color-alto-bis;
  }
  &__stores-header {
    flex-direction: column;
    align-items: flex-start;
  }
}

.store-locator-small {
  &__stores-item {
    height: initial;
    &:not(:first-child) {
      border-left: 1px solid $color-alto-bis;
    }
    .store-locator-store-card {
      padding: 0 1em;
    }
  }
}

.store-locator-large,
.store-locator-small {
  .main-title {
    padding-left: 0;
    &__title {
      margin-top: 0;
      font-family: $font-secondary;
      font-size: px-to-rem(56px);
      font-weight: map-get($font-weight, semi-bold);
      text-align: center;
      padding: px-to-rem(20px);
      @include breakpoint($breakpoint-switch) {
        padding: 0;
      }
    }
  }
}

.leaflet-container a.leaflet-popup-close-button {
  color: $color-primary !important;
  &:hover {
    color: darken($color-primary, 5%) !important;
  }
}

.leaflet-popup-content {
  margin-right: px-to-rem(40px) !important;
}
