@import "~tarteaucitronjs/css/tarteaucitron.css";

body {
  #tarteaucitronDisclaimerAlert {
    flex-grow: 1;
  }

  #tarteaucitronRoot {
    &.tarteaucitronBeforeVisible {
      &::before {
        z-index: $z-index-tarteaucitron-backdrop !important;
      }
    }

    * {
      font-family: $font-lato, sans-serif !important;
    }

    div#tarteaucitronAlertBig {
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;
      max-width: px-to-em(480px);
      min-width: px-to-em(250px);
      background: $color-black;

      @include breakpoint($breakpoint-switch) {
        flex-direction: row;
        max-width: px-to-em(960px);
        width: 95% !important;
      }

      &::before {
        display: none;
      }

      #tarteaucitronDisclaimerAlert,
      #tarteaucitronDisclaimerAlert strong,
      #tarteaucitronPrivacyUrl,
      #tarteaucitronPrivacyUrlDialog,
      #tarteaucitronCloseAlert,
      #tarteaucitronPersonalize2,
      .tarteaucitronAllow,
      .tarteaucitronCTAButton,
      .tarteaucitronDeny {
        font-size: 16px !important;
        transition-property: background-color, color;
      }

      #tarteaucitronDisclaimerAlert,
      #tarteaucitronDisclaimerAlert strong,
      #tarteaucitronPrivacyUrl,
      #tarteaucitronPrivacyUrlDialog {
        margin-top: px-to-em(20px);
      }

      #tarteaucitronCloseAlert,
      #tarteaucitronPersonalize2,
      .tarteaucitronAllow,
      .tarteaucitronCTAButton,
      .tarteaucitronDeny {
        @extend %base-btn;

        height: px-to-em(50px);
        width: 100%;
        margin: 0 0 px-to-em(15px);
        padding: 0;
        text-transform: uppercase;

        @include breakpoint($breakpoint-switch) {
          width: px-to-em(270px);
          margin-bottom: 0;
        }
      }

      #tarteaucitronPersonalize2,
      .tarteaucitronAllow,
      .tarteaucitronCTAButton,
      .tarteaucitronDeny {
        @extend %base-btn--inverted;

        @include hover-focus-within-workaround() {
          background-color: $color-secondary;
        }
      }

      #tarteaucitronCloseAlert {
        border: 1px solid $color-white;

        @include hover-focus-within-workaround() {
          background-color: $color-white;
          color: $color-primary;
        }
      }
    }

    .tarteaucitronAlertBig {
      &__container {
        width: 100%;
        text-align: center !important;
      }

      &__logo {
        max-height: px-to-em(75px);
      }
    }
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine,
  #tarteaucitron .tarteaucitronBorder,
  #tarteaucitronAlertSmall
    #tarteaucitronCookiesListContainer
    #tarteaucitronCookiesList,
  #tarteaucitronAlertSmall
    #tarteaucitronCookiesListContainer
    #tarteaucitronCookiesList
    .tarteaucitronCookiesListMain,
  #tarteaucitronAlertSmall
    #tarteaucitronCookiesListContainer
    #tarteaucitronCookiesList
    .tarteaucitronHidden {
    border-color: $color-primary !important;
  }

  #tarteaucitron {
    #tarteaucitronServices .tarteaucitronMainLine,
    #tarteaucitronClosePanel,
    #tarteaucitronInfo,
    #tarteaucitronServices .tarteaucitronTitle button {
      &,
      &:hover {
        background: $color-primary;
      }
    }

    #tarteaucitronServices {
      .tarteaucitronLine {
        .tarteaucitronAsk {
          .tarteaucitronAllow,
          .tarteaucitronDeny,
          .tac_activate .tarteaucitronAllow {
            @extend %base-btn;
            @extend %base-btn--inverted;

            height: px-to-em(50px);
            padding-top: 0;
            padding-bottom: 0;
            opacity: 1 !important;

            @include hover-focus-within-workaround() {
              background-color: $color-secondary;
            }
          }
        }
      }

      #tarteaucitronAllAllowed,
      #tarteaucitronAllDenied {
        &.tarteaucitronIsSelected {
          background-color: $color-secondary;
        }
      }
    }
  }

  .tarteaucitronCheck,
  .tarteaucitronCross {
    display: none;
  }
}
