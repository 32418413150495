.pagination {
  column-gap: 0.5rem;
  padding: 0;

  &__button--selected {
    border-radius: px-to-rem(5px);
    background-color: $color-athens-gray-2;
  }
  &__button--prev,
  &__button--next {
    background-color: transparent;
    border-radius: 50%;
  }

  &__limiter {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.5rem;

    .form-input--select {
      display: flex;
      column-gap: 0.5rem;
      .form-input__label {
        margin-right: 0;
        width: fit-content;
      }
      .form-input__input {
        background-color: transparent;
      }
    }
  }
}
