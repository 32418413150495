.page-not-found {
  &__404 {
    .a {
      display: none;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: center;
  }

  &__error-page-number {
    font-family: $font-teko;
    font-weight: map-get($font-weight, semi-bold);
    font-size: px-to-rem(190px);
    color: $color-primary;
    line-height: 1;
    &:first-of-type {
      transform: translateX(100px);
    }
    &:last-of-type {
      transform: translateX(-100px);
    }
  }

  &__text {
    font-weight: map-get($font-weight, semi-bold);
    font-size: px-to-em(56px);
    font-family: $font-teko;
    color: $color-black;
    margin-bottom: px-to-rem(44px);
  }
}
