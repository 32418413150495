.banner-keywords {
  background-color: $color-cruise;

  @include breakpoint($breakpoint-switch) {
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 45%;
      right: 0;
    }

    &::before {
      z-index: 2;
      background-color: $color-bermuda;
      right: 5%;
      clip-path: polygon(0 0, 100% 0%, 75% 100%, 25% 100%);
    }
    &::after {
      z-index: 1;
      background-color: $color-humming-bird;
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 25% 100%);
    }
  }

  &__img {
    position: absolute;
    display: none;
    right: 0;
    bottom: px-to-rem(60px);
    max-width: px-to-rem(380px);
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 5% 100%);
    @include breakpoint($breakpoint-switch) {
      display: block;
    }
  }

  &__container {
    flex-direction: column;
    justify-content: center;
    gap: 1em;
    width: auto;
    padding: px-to-em(55px) 0 px-to-em($gap-l);
    margin: 0 px-to-rem(110px);
    color: $color-black;

    @include breakpoint($breakpoint-switch) {
      width: 40%;
    }
  }

  &__right-wrapper,
  &__left-wrapper {
    padding: 0;
  }

  &__right-wrapper {
    display: flex;
    gap: $size-xl;

    .icon--ok-check {
      font-weight: map-get($font-weight, semi-bold);
    }
  }

  &__title {
    font-family: $font-secondary;
    font-weight: map-get($font-weight, semi-bold);
    font-size: $size-xxl;
    margin-bottom: 0;
  }

  &__content {
    font-size: $size-s;
    font-family: $font-default;
    font-weight: 100;
  }

  &__keyword {
    gap: 0.5em;
    font-weight: map-get($font-weight, medium);
    font-size: $size-m-intermediate;
    font-family: $font-lato;
  }
}
