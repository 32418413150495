.cms-page-view {
  &:not(.page-layout-light-page) {
    .page-main {
      @extend .margin-after-breadcrumbs;
    }

    .main {
      @extend .wysiwyg;
      @extend .wysiwyg--revert-base;

      p {
        &:not([class]) {
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
  }
}
