.blog-listing {
  &__filter {
    background-color: $color-boticelli;

    .form-input {
      &::before {
        top: unset;
      }

      &__input {
        $height: px-to-em(40px);

        height: $height;
        line-height: $height;
      }
    }
  }

  &__filter-container {
    @extend .gui-container;

    padding: 1em;
  }

  &__grid {
    margin-top: px-to-em(50px);
  }
}
