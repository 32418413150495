.slider-cms {
  @include breakpoint($breakpoint-switch) {
    width: 100%;
  }

  &__title {
    line-height: 1;
    text-align: left;
    font-size: px-to-rem(30px);
    font-weight: map-get($font-weight, normal);

    @include breakpoint($breakpoint-switch) {
      line-height: 1.5;
      width: 40%;
      margin: 0 px-to-rem(110px);
      font-size: px-to-rem(40px);
    }
  }

  &__content-wrapper {
    text-align: left;
    @include breakpoint($breakpoint-switch) {
      width: 40%;
      margin: 0 px-to-rem(110px);
    }
  }

  &__pagination {
    justify-content: center;
  }

  &__button {
    height: px-to-rem(50px);
    border-radius: px-to-rem(29px);
    border: solid 1px white;
    margin: 0;
  }

  .swiper-pagination-bullet {
    height: px-to-rem(14px);
    width: px-to-rem(14px);
    background-color: white;
  }

  &__title,
  &__description {
    color: $color-black;
    @include breakpoint($breakpoint-switch) {
      color: $color-white;
    }
  }

  &__title,
  &__description,
  &__button {
    margin-left: px-to-rem(14px);
    margin-right: px-to-rem(14px);
    @include breakpoint($breakpoint-switch) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__description {
    margin-top: px-to-rem(10px);
  }

  &__slide {
    position: relative;
    .gui-container {
      text-align: left;
      @include breakpoint($breakpoint-switch) {
        margin: 0 6.875rem;
      }
    }
  }

  &__image {
    margin-bottom: 0;
    z-index: -2;
    position: relative;
  }

  &__image-wrapper {
    position: relative;
    @include breakpoint($breakpoint-switch) {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }

  &__short-triangle-layer,
  &__parallelogram-layer,
  &__triangle-layer {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    mix-blend-mode: multiply;
    isolation: isolate;
  }

  &__triangle-layer {
    left: 5%;
    width: 60%;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    background-color: #b5efe0;
  }

  &__parallelogram-layer {
    width: 60%;
    clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
    background-color: #c6ecf7;
  }

  &__short-triangle-layer {
    width: 45%;
    height: 80%;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    background-color: #d9d9d9;
  }
}
