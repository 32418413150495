.reinsurance {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &--with-top-separator {
    @extend %separator;
  }

  &__item {
    width: 50%;
    margin-bottom: px-to-em(35px);
    padding: 0 px-to-em(5px);
  }

  &__pictogram {
    margin: 0 auto px-to-em(20px) auto;
    width: px-to-em(121px);
    height: px-to-em(107px);
    position: relative;

    svg {
      fill: $color-primary;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  &__title {
    text-align: center;
    font-weight: map-get($font-weight, bold);
    margin-bottom: px-to-em(9px);
  }

  &__baseline {
    text-align: center;
    font-weight: map-get($font-weight, light);
    max-width: px-to-em(178px, 14px);
    font-size: px-to-em(14px);
    margin: 0 auto;
  }

  @include breakpoint($breakpoint-switch) {
    padding-top: px-to-em(100px);

    &__item {
      width: 25%;
      margin-bottom: px-to-em(45px);
    }

    &__pictogram {
      margin-bottom: px-to-em(15px);
      width: px-to-em(143px);
      height: px-to-em(127px);
    }
  }
}
