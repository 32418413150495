$font-default: $font-montserrat, $font-system;
$font-secondary: $font-teko, $font-system;

// ## Font size
$size-xxs: 0.75rem; // 12px
$size-xs: 0.875rem; // 14px
$size-s: 1rem; // 16px
$size-m: 1.125rem; // 18px
$size-m-intermediate: 1.25rem; // 20px
$size-l: 1.563rem; // 25px
$size-xl: 2rem; // 32px
$size-xl-intermediate: 2.5rem; // 40px
$size-xxl: 3.5rem; // 56px
