%base-text {
  $font-size: 16px;

  font-family: $font-secondary;
  font-weight: map-get($font-weight, light);
  font-size: px-to-em($font-size);
  line-height: px-to-em(20px, $font-size);
}

%base-text--default {
  font-size: px-to-em(16px);
}

%base-text--small {
  font-size: px-to-em(14px);
}
