.slider-cms {
  @include breakpoint($breakpoint-switch) {
    height: px-to-rem(490px);
  }

  &__slide {
    height: 100%;
    width: 100%;
    position: relative;
    text-align: center;

    @include breakpoint($breakpoint-switch) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: rgba(0, 0, 0, 25%);
      }
    }
  }

  &__title {
    margin-bottom: px-to-rem(15px);
    margin-top: px-to-rem(15px);
    @extend .gui-container;

    @include breakpoint($breakpoint-switch) {
      color: white;
    }
  }

  &__image {
    margin-bottom: px-to-rem(15px);
    min-height: px-to-rem(215px);
    object-fit: cover;

    @include breakpoint($breakpoint-switch) {
      position: absolute;
      z-index: -2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__description {
    margin-bottom: px-to-rem(15px);
    font-weight: map-get($font-weight, light);

    @include breakpoint($breakpoint-switch) {
      color: white;
    }
  }

  &__button {
    margin: 0 auto;
    @extend .base-btn--primary;
  }

  &__nav {
    display: none;
  }

  &__pagination {
    display: none;

    @include breakpoint($breakpoint-switch) {
      display: flex;
    }
  }
}
