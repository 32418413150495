$base-class: ".main-title";
#{$base-class} {
  &--align-center {
    font-family: $font-default;
    text-align: center;
    margin-bottom: 2em;

    #{$base-class}__title {
      &--h1 {
        font-size: $size-xxl;
      }
      &--h2 {
        font-size: $size-xl-intermediate;
        font-weight: map-get($font-weight, light);
      }
    }
  }

  &--text-center {
    font-family: $font-secondary;
    text-align: center;
    margin-bottom: 2em;

    #{$base-class}__title {
      &--h1 {
        font-size: $size-xxl;
      }
    }
  }

  &--with-decoration {
    position: relative;
    padding-left: px-to-rem(30px);

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: px-to-rem(10px);
      background: $color-picton-blue;
    }

    &:has(#{$base-class}__title--h1) {
      &::before {
        top: px-to-rem(16px);
        height: 50%;
      }
    }

    &:has(#{$base-class}__title--h2) {
      &::before {
        top: px-to-rem(13px);
        height: 57%;
      }
    }

    &:has(#{$base-class}__title--h3) {
      &::before {
        height: 90%;
      }
    }
  }

  &__title {
    margin-bottom: 0;
    margin-top: 2rem;

    &--h1 {
      font-family: $font-teko;
      font-weight: map-get($font-weight, semi-bold);
      font-size: $size-xl-intermediate;

      @include breakpoint($breakpoint-s) {
        font-size: $size-xxl;
      }
    }

    &--h2 {
      font-family: $font-montserrat;
      font-size: $size-xl-intermediate;
    }

    &--h3 {
      font-family: $font-teko;
      font-weight: map-get($font-weight, semi-bold);
      font-size: $size-m-intermediate;
      text-transform: uppercase;
    }
  }
}
