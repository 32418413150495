$base-class: ".form-input";

#{$base-class} {
  &__label {
    font-family: $font-default;
  }

  &__input {
    font-family: $font-default;
    border: 2px solid $color-spring-wood;
    border-radius: px-to-em(30px);
    padding: 0 px-to-em(20px);
    &::placeholder {
      color: $color-nobel;
    }
    &:placeholder-shown {
      font-style: italic;
    }

    &:invalid,
    &.mage-error {
      border: 2px solid $color-spring-wood;
    }
  }

  &--select {
    &::before {
      right: px-to-em(10px);
      color: $color-primary;
    }

    &.icon--angle-down {
      &::before {
        top: px-to-rem(40px);
        right: px-to-rem(15px);
      }
    }

    #{$base-class}__input {
      &:invalid {
        color: #b7b7b7;
        font-family: $font-default;
        font-style: italic;
      }
      option {
        color: $color-black;
      }
    }
  }

  &--textarea {
    width: 100%;
    #{$base-class}__input {
      @include breakpoint($breakpoint-switch) {
        max-width: px-to-rem(700px);
      }
      min-height: px-to-em(200px);
      max-height: px-to-em(500px);
      padding: 1em 1.2em;
      border-radius: px-to-em(30px);
      resize: both;
    }
  }
}
