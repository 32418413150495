$base-class: ".reinsurance-product";

#{$base-class} {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: px-to-rem(40px);
  overflow: auto;

  &__item {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__icon {
    width: 100%;
    height: px-to-rem(260px);
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    #{$base-class}__svg {
      width: px-to-rem(100px);
      height: px-to-rem(100px);
      color: $color-black;
      margin-bottom: 0.5em;
      bottom: 0;
    }
  }

  &__title {
    margin: px-to-rem(10px) 0 0 0;
    text-transform: uppercase;
    text-align: center;
    font-size: px-to-rem(16px);
    font-weight: map-get($font-weight, "medium");
  }

  &__description {
    text-align: center;
    font-weight: map-get($font-weight, "medium");
    min-width: px-to-rem(230px);
  }
}
