.tabs-slider {
  &__title {
    text-align: center;
    font-size: px-to-rem(25px);
    font-weight: map-get($font-weight, light);

    @include breakpoint($breakpoint-switch) {
      font-size: px-to-rem(40px);
      margin-bottom: px-to-rem(20px);
    }
  }

  &__select-container {
    border: solid 2px $color-ebb;
    border-radius: px-to-rem(25px);
    padding: 0 1em;
  }

  &__select {
    border: none;
    text-align: left;
    background: transparent;
    color: $color-primary;
    font-family: $font-secondary;
  }

  &__label {
    &--active {
      color: black;

      &::before {
        content: "//";
        margin-right: px-to-rem(5px);
      }
    }

    &:hover:not(&--active) {
      color: $color-silver-chalice;
    }
  }

  &__slider {
    padding: px-to-em(22px) px-to-em(35px) px-to-em(22px) px-to-em(10px);

    @include breakpoint($breakpoint-switch) {
      flex: 0.42;
      padding: px-to-em(60px) px-to-em(110px) px-to-em(70px) px-to-em(45px);
      margin-right: 0;
    }
  }

  &__slide {
    align-items: center;
    padding: px-to-em(27px) px-to-em(10px) px-to-em(10px) px-to-em(10px);

    @include breakpoint($breakpoint-switch) {
      padding: 2em;
    }

    @include breakpoint($breakpoint-xl) {
      width: px-to-rem(478px);
    }
  }

  &__tab-content {
    background-color: $color-wild-sand;

    &--active {
      flex: 0.5;
      justify-content: flex-end;
    }
  }

  &__tab-img-container {
    height: px-to-em(170px);
    width: 80%;

    @include breakpoint($breakpoint-xs) {
      height: px-to-em(300px);
    }

    @include breakpoint($breakpoint-switch) {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      pointer-events: none;
      flex-grow: 1;
      width: 60%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      clip-path: polygon(0 0, 100% 0, 66% 100%, 0 100%);
    }
  }

  &__product {
    flex-direction: column;
    align-items: center;
    row-gap: 1em;
  }

  &__product-infos {
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
  }

  &__product-name {
    text-align: center;
    font-family: $font-secondary;
    color: black;
    font-weight: map-get($font-weight, medium);
    margin-bottom: 0;
    height: 1em;
  }

  &__product-description {
    color: black;
    font-weight: map-get($font-weight, normal);
    text-align: left;
  }

  &__article-url-button {
    width: fit-content;
    align-self: center;
  }

  &__nav {
    padding: px-to-rem(10px);
    color: $color-primary;

    &.swiper-navigation {
      &::after {
        font-size: 20px;
        font-weight: map-get($font-weight, bold);
      }

      &--next {
        right: 4.7em;
      }
    }

    &.swiper-button-disabled {
      opacity: 1;
      color: $color-nobel;
    }
  }
}
