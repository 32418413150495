.inline-product-list {
  &__slider {
    position: relative;
  }

  &__button {
    text-align: center;
  }

  .product-card {
    margin: auto;
  }
}
