.slider-projects {
  padding: px-to-em($gap-xl) 0;

  @include breakpoint($breakpoint-switch) {
    padding: px-to-em($gap-xxxl) 0;
  }

  &__container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    font-family: $font-secondary;
    font-size: px-to-rem(14px);
    font-weight: map-get($font-weight, light);
    margin-bottom: px-to-rem(35px);
    max-width: px-to-rem(530px);
    text-align: center;
  }

  &__outer-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    padding: 0 px-to-em($gap-m) 0 px-to-em($gap-xs);
    @include breakpoint($breakpoint-switch) {
      padding: 0;
    }
  }

  &__nav {
    top: 35%;
    background: none;
    border: none;
  }

  &__button {
    margin-top: px-to-em($gap-m);
  }

  &__card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;

    @include breakpoint($breakpoint-switch) {
      &:hover {
        opacity: 1;
      }
    }
  }

  .swiper-navigation {
    &--prev {
      left: 0;
      @include breakpoint($breakpoint-xl) {
        left: -2em;
      }
    }

    &--next {
      right: 0;
      @include breakpoint($breakpoint-xl) {
        right: -2em;
      }
    }
  }
}
