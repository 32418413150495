$base-class: ".form-input";

#{$base-class} {
  $input-padding: px-to-em($gap-xxs, 14px);

  display: flex;
  flex-direction: column;
  width: 100%;

  @include breakpoint($breakpoint-switch) {
    width: calc(50% - 10px);
  }

  &--full {
    @include breakpoint($breakpoint-switch) {
      width: 100%;
    }
  }

  &__label {
    width: 100%;
    margin-bottom: px-to-em(7px);
  }

  &__input {
    height: px-to-em(50px, 14px);

    &:invalid,
    &.mage-error {
      border-color: $color-monza;
    }
  }

  &--select {
    position: relative;

    &#{$base-class} {
      &--inline {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        width: auto;

        &.icon--angle-down {
          &::before {
            bottom: 50%;
            transform: translateY(50%);
          }
        }

        #{$base-class} {
          &__label {
            margin-right: px-to-em(10px);
            margin-bottom: 0;
          }
        }
      }
    }

    &.icon--angle-down {
      &::before {
        position: absolute;
        top: 36px;
        right: 7px;
        z-index: 3;
        pointer-events: none;
        font-size: 25px;
      }
    }

    #{$base-class}__input {
      font-family: $font-lato;
      padding-right: 2rem;
      appearance: none;
    }
  }

  .mage-error {
    font-family: $font-lato;
    color: $color-monza;
  }
}
