@import "contact-form";

.contact-form {
  &__text {
    font-family: $font-default;
    font-weight: map-get($font-weight, normal);
  }

  &__image-container {
    margin-top: 7em;
  }

  &__form {
    > .custom-checkbox {
      margin-top: 1em;
    }
    .form-input {
      margin-top: 1em;
      @include breakpoint($breakpoint-l) {
        margin-top: 0;
      }
    }
  }

  &__row {
    @include breakpoint($breakpoint-s) {
      .form-input + .form-input {
        margin-top: 1em;
      }
    }
    @include breakpoint($breakpoint-l) {
      margin-top: 0;
    }
  }

  @include breakpoint($breakpoint-l) {
    .form-input {
      margin-top: 1em;
    }
  }
}
