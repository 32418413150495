.quote {
  background: $color-cruise;
  position: relative;
  overflow: hidden;

  @include breakpoint($breakpoint-s) {
    min-height: px-to-rem(380px);
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 2;
    height: 120%;
    width: 140%;
    top: px-to-rem(-10px);
    left: 0;
    background: linear-gradient(
          to top right,
          transparent 50%,
          $color-bermuda 50%
        )
        left,
      linear-gradient(to top left, transparent 50%, $color-bermuda 50%) right;
    background-size: 51% 100%;
    background-repeat: no-repeat;

    @include breakpoint($breakpoint-s) {
      right: 0;
      left: unset;
      width: px-to-rem(740px);
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 30%;
    top: 0;
    right: 0;
    background: $color-humming-bird;

    @include breakpoint($breakpoint-s) {
      width: px-to-rem(370px);
    }
  }

  &__wrapper {
    position: relative;
    z-index: 5;
    padding: px-to-rem(36px) px-to-rem(20px);

    @include breakpoint($breakpoint-s) {
      padding: px-to-rem(100px) px-to-rem(50px);
    }

    @include breakpoint($breakpoint-xl) {
      padding: px-to-rem(100px) px-to-rem(160px);
    }
  }

  &__content {
    position: relative;

    @include breakpoint($breakpoint-s) {
      max-width: calc(100% - #{px-to-rem(387px)});
    }

    @include breakpoint($breakpoint-xl) {
      max-width: px-to-rem(690px);
    }
  }

  &__text {
    font-size: $size-m-intermediate;
    color: $color-black;
    z-index: 10;
    position: relative;

    @include breakpoint($breakpoint-s) {
      font-size: px-to-rem(30px);
    }
  }

  &__icon {
    color: $color-white;
    opacity: 0.3;
    font-size: px-to-rem(87px);
    position: absolute;
    top: 50%;
    z-index: 4;

    &--reverse {
      transform: translateY(-70%) rotate(180deg);
      left: 0;

      @include breakpoint($breakpoint-s) {
        transform: translateY(-50%) rotate(180deg);
        left: px-to-rem(-50px);
      }
    }

    &:not(&--reverse) {
      right: 0;
      transform: translateY(-30%);

      @include breakpoint($breakpoint-s) {
        right: px-to-rem(-120px);
        transform: translateY(-50%);
      }
    }
  }

  &__image {
    display: none;

    @include breakpoint($breakpoint-s) {
      display: block;
      position: absolute;
      z-index: 4;
      right: 0;
      top: 0;
      max-width: px-to-rem(387px);
      clip-path: polygon(35% 0, 100% 0, 100% 100%, 0% 100%);
    }
  }

  &__name {
    font-family: $font-secondary;
    display: block;
    margin-top: 0.5rem;
  }

  &__role {
    display: block;
    font-style: italic;
    font-size: $size-xs;
  }
}
