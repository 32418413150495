@import "reinsurance";

.reinsurance {
  background-color: $color-cruise;
  color: $color-honey-flower;
  display: flex;
  justify-content: center;
  row-gap: px-to-em(30px);
  padding: px-to-em(30px);
  @include breakpoint($breakpoint-switch) {
    padding: 0 0 px-to-em(30px) 0;
  }

  &__item {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    @include breakpoint($breakpoint-switch) {
      width: px-to-rem(250px);
    }
  }

  &__pictogram,
  &__title,
  &__baseline {
    margin: 0;
  }

  &__pictogram {
    margin-bottom: px-to-rem(20px);
    height: px-to-rem(60px);

    svg {
      fill: $color-honey-flower;
      max-height: px-to-em(60px);
    }

    @include breakpoint($breakpoint-switch) {
      height: px-to-rem(107px);
    }
  }

  &__title {
    text-transform: uppercase;
    font-family: $font-montserrat;
    margin-bottom: px-to-rem(10px);
  }
}
