.store-locator-icon {
  width: px-to-rem(40px);
  height: px-to-rem(40px);
  font-size: px-to-em(20px);
  border-radius: 50%;
  background-color: $color-primary;
  transition: all 0.5s;

  &:hover,
  &--highlighted {
    background-color: $color-black;
    transform: scale(1.35);
  }
}
