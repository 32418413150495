.base-btn {
  position: relative;
  font-weight: map-get($font-weight, medium);
  height: auto;
  min-height: px-to-rem(50px);
  line-height: 1.5;

  &:hover {
    background: $color-pelorus;
  }

  &:not(&--tertiary) {
    &::before {
      content: "//";
      margin-right: px-to-rem(5px);
      transition: $medium-transition;
    }
  }

  &--primary {
    &::before {
      color: white;
    }
  }

  &--secondary {
    border: solid 2px $color-primary;

    &::before {
      color: $color-primary;
    }

    &:hover {
      color: white;
      border-color: $color-pelorus;

      &::before {
        color: white;
      }
    }
  }

  &--tertiary {
    background: $color-honey-flower;
    font-size: $size-xs;
    height: px-to-rem(26px);
    padding: 0 1rem;
  }
}
