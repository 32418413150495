.tooltip {
  display: inline-block;

  @include breakpoint($breakpoint-switch) {
    position: relative;
  }

  &__text {
    z-index: 1;
    visibility: hidden;
    position: absolute;
    right: px-to-rem(5px);
    left: px-to-rem(5px);
    background-color: $color-alto;
    color: black;
    padding: px-to-rem(14px);
    border-radius: px-to-rem(7px);

    @include breakpoint($breakpoint-switch) {
      top: 130%;
      left: unset;
      right: 0;
      width: max-content;
      max-width: px-to-rem(300px);
    }
  }

  &:hover {
    .tooltip__text {
      visibility: visible;
    }
  }
}
