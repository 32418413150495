.footer {
  ul {
    padding: 0;
    margin: 0;
  }

  position: relative;
  overflow: hidden;

  &__content {
    position: relative;
    z-index: 1;
  }

  &__title {
    display: block;
    margin-bottom: px-to-em(15px);
  }

  &__mention {
    border-top: solid 1px $color-east-bay;
    font-weight: map-get($font-weight, light);
    padding: px-to-em(25px) 0;
    color: $color-white;
    font-family: $font-secondary;
  }

  &__col {
    margin-bottom: px-to-em(20px);
  }

  &__link {
    font-size: px-to-em(14px);

    &:hover {
      text-decoration: underline;
    }
  }

  &__newsletter {
    margin-bottom: px-to-em(20px);
  }

  &__social {
    li {
      display: inline-block;
    }
  }

  &__social-link {
    width: px-to-em(40px);
    height: px-to-em(40px);
    border: solid 1px $color-white;
    border-radius: px-to-em(20px);
    display: inline-block;
    position: relative;
    transition: border-color $default-transition-duration;
    margin-right: px-to-em(8px);

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      transition: fill $default-transition-duration;
      fill: $color-white;
    }
  }
}
