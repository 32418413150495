.contact-form {
  @extend .gui-container;

  display: flex;
  flex-direction: row;
  padding: 0 1em 3em;

  @include breakpoint($breakpoint-switch) {
    padding-right: 0;
    padding-left: 0;
  }

  &__text {
    @extend %base-text;
  }

  &__row {
    @include breakpoint($breakpoint-s) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      .form-input + .form-input {
        margin-top: 0;
      }
    }
  }

  &__button-container {
    margin-top: px-to-em(22px);
    text-align: center;

    @include breakpoint($breakpoint-l) {
      text-align: left;
    }
  }

  &__image-container {
    display: none;

    @include breakpoint($breakpoint-l) {
      flex-shrink: 0;
      display: block;
      margin-left: px-to-em(64px);
    }
  }
}
