.slider-projects {
  &__title {
    font-size: px-to-rem(25px);
    font-weight: map-get($font-weight, normal);
    margin-bottom: px-to-rem(20px);

    @include breakpoint($breakpoint-switch) {
      font-size: px-to-rem(40px);
    }
  }

  &__news-block {
    .news-card__img-container {
      height: px-to-rem(159px);
      width: px-to-rem(159px);
    }
  }

  &__card-overlay {
    @include breakpoint($breakpoint-switch) {
      &:hover {
        background-color: rgba($color-humming-bird, 0.35);
      }
    }
  }

  &__overlay-button {
    height: px-to-rem(50px);
    border-radius: px-to-rem(29px);
  }

  &__nav {
    color: $color-primary;

    &.swiper-navigation {
      &::after {
        font-size: 20px;
        font-weight: map-get($font-weight, bold);
      }
    }
  }
}
