$product-card-class: ".product-card";

.inline-product-list {
  &__slider-nav {
    background: none;
    border: none;

    &.swiper-button-prev {
      @include breakpoint($breakpoint-switch) {
        left: px-to-rem(-30px);
      }
    }

    &.swiper-button-next {
      @include breakpoint($breakpoint-switch) {
        right: px-to-rem(-30px);
      }
    }

    &::after {
      font-size: px-to-rem(30px);
      font-weight: bold;
      color: $color-primary;
    }
  }

  &__slide {
    @include breakpoint($breakpoint-switch) {
      width: fit-content !important;
      margin-right: 0 !important;
    }

    #{$product-card-class} {
      margin-right: px-to-rem(5px);
      display: flex;
      flex-direction: column;
      align-items: center;
      @include breakpoint($breakpoint-switch) {
        width: px-to-rem(230px);
        padding: px-to-rem(10px);
      }

      &__img {
        object-fit: contain;
        @include breakpoint($breakpoint-switch) {
          max-width: unset !important;
          margin: 0 !important;
          width: 90%;
        }
      }

      &__text {
        padding: 0 1.5rem;
      }

      &__overlay {
        padding: 0;
      }
    }
  }
}
