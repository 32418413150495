// ## Theme
$color-primary: $color-picton-blue;
$color-primary-invert: if($color-primary, text-contrast($color-primary), null);

$color-default: $color-tuatara;
$color-default-selection: null;
$color-default-background: null;
$color-default-link: $color-blue;

$color-secondary: $color-pelorus;
$color-secondary-invert: if(
  $color-secondary,
  text-contrast($color-secondary),
  null
);

$color-warning: $color-yellow;
$color-error: $color-monza;

$color-footer-background-1: #e7f4fa;
$color-footer-background-2: #d0eaf5;
$color-footer-background-3: #bccde1;
$color-footer-background-4: #d1d6e6;
