// # Icon placeholders
//
// Icon placeholder benefits:
// - No extra markup nor CSS classes required
// - Better separation of concerns: HTML for content, CSS for styles
// - The computed CSS only contains icon selectors that you really use.
//
// When to use icon placeholders over icon classes?
// Icon placeholders are the default way to go. Icon classes should only
// be used for dynamic use cases (ie. when the used icon is defined
// programmatically)
//
// Usage:
// ```scss
// .example-selector {
//   &::before {
//     @extend %icon;
//     @extend %icon--example;
//     // Do not take line-height into account (optionnal)
//     //display: block;
//   }
// }
// ```
//
// Replacement RegEx when importing from regular CSS (works with PhpStorm):
// `\.icon-([\w-]+):before\s?\{`
// `%icon--$1 {`

%icon {
  font-family: $font-icons;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  speak: never; // See https://css-tricks.com/almanac/properties/s/speak/
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%icon--angle-down {
  content: "\66";
}

%icon--angle-left {
  content: "\67";
}

%icon--angle-right {
  content: "\68";
}

%icon--angle-up {
  content: "\69";
}

%icon--ok-check {
  content: "\61";
}

%icon--menu {
  content: "\62";
}

%icon--close {
  content: "\63";
}

%icon--arrow-left {
  content: "\64";
}

%icon--arrow-right {
  content: "\65";
}

%icon--magnifier {
  content: "\6a";
}

%icon--marker {
  content: "\6b";
}

%icon--assistance {
  content: "\6c";
}

%icon--history {
  content: "\6d";
}

%icon--quote {
  content: "\6e";
}

%icon--info {
  content: "\6f";
}
