.pre-header {
  background-color: $color-black;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 px-to-em(10px);
  width: 100%;
  @include breakpoint($breakpoint-switch) {
    position: fixed;
    top: 0;
    z-index: 100;
  }

  &__link {
    font-family: $font-lato;
    font-size: px-to-rem(14px);
    font-weight: map-get($font-weight, regular);
    text-wrap: nowrap;
  }

  .lang-switcher {
    &__checkbox,
    &__list {
      display: none;
    }

    &__label {
      display: flex;
      align-items: center;
      gap: 0.5em;
      color: $color-white;
    }
  }
}
