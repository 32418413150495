@import "tarteaucitron";

body > #tarteaucitronRoot {
  $darken-primary-color: darken($color-primary, 10%);
  @mixin primary-button() {
    background-color: $color-primary !important;
    color: $color-white !important;

    &:hover {
      background-color: $darken-primary-color !important;
    }
  }
  @mixin secondary-button() {
    border: 2px solid $color-primary !important;
    color: $color-primary !important;
    background-color: $color-black !important;
    &:hover {
      border: 2px solid $darken-primary-color !important;
      color: $darken-primary-color !important;
      background-color: $color-black !important;
    }
  }

  .tarteaucitronAlertBigTop {
    #tarteaucitronDisclaimerAlert {
      font-family: $font-default;
    }

    .tarteaucitronCTAButton {
      @include primary-button();

      @include breakpoint($breakpoint-l) {
        margin-bottom: px-to-rem(10px) !important;
      }
    }

    #tarteaucitronCloseAlert {
      @include secondary-button();
    }
  }

  #tarteaucitron {
    #tarteaucitronClosePanel {
      border-radius: px-to-rem(5px) px-to-rem(5px) 0 0 !important;
      font-size: px-to-rem(14px);
    }

    .tarteaucitronAllow {
      @include primary-button();
    }
    .tarteaucitronDeny {
      @include secondary-button();
    }

    .tarteaucitronMainLine {
      background-color: $color-black !important;
      border-width: 0 !important;
      border-color: unset !important;
      border-top-left-radius: px-to-rem(10px);

      #tarteaucitronInfo {
        background-color: unset !important;
      }
    }

    .tarteaucitronBorder {
      border: none !important;
      border-bottom-left-radius: px-to-rem(10px);
      border-bottom-right-radius: px-to-rem(10px);
    }
  }
}

div#tarteaucitronServices {
  height: 100% !important;
  border-radius: px-to-rem(10px) 0 px-to-rem(10px) px-to-rem(10px) !important;
  margin-top: px-to-rem(25px) !important;

  #tarteaucitronNoServicesTitle {
    font-size: $font-size-s;
    font-weight: map-get($font-weight, "medium");
    padding-left: 1em !important;
  }
}
