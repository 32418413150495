.page-header {
  & > .pre-header,
  & > .main-header-part,
  & > .menu,
  & > .mega-menu {
    display: none;
  }
}

@include breakpoint($breakpoint-switch) {
  .page-header {
    & > .pre-header,
    & > .main-header-part,
    & > .menu {
      display: flex;
    }

    & > .mega-menu {
      display: block;
    }

    & > .menu-mobile {
      display: none;
    }
  }
}
