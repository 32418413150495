$base-class: ".menu-mobile";
#{$base-class} {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  padding-top: $menu-mobile-head-height;
  position: fixed;
  z-index: $z-index-mobile-menu;
  margin-bottom: $menu-mobile-head-height;
  top: 0;
  left: 0;

  &__first-strate {
    position: relative;
    z-index: 1;
    padding: 0;
    width: 100%;
    height: calc(100vh - 65px);
    overflow-x: hidden;
    background-color: $color-primary;
    transform: translateX(100%);
    transition: $medium-transition;
    margin-bottom: 0;
    pointer-events: all;

    &--active {
      transform: translateX(0);
    }
  }

  &__head {
    width: 100%;
    height: $menu-mobile-head-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 1em;
    z-index: 10;
    pointer-events: all;
  }

  &__search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: px-to-em(20px);
    border: none;
    color: $color-white;
    cursor: pointer;

    &--hidden {
      opacity: 0;
    }
  }

  &__search-block {
    width: 100%;
    pointer-events: all;
    position: absolute;
    top: px-to-em(-70px);
    transition: $medium-transition;

    &--active {
      top: $menu-mobile-head-height;
    }

    .input-field {
      &__input {
        &::placeholder {
          font-size: 1em;
          color: $color-silver-chalice;
        }
      }
    }

    .input-field__button {
      margin-left: -1em;
      border-radius: 50%;
      color: $color-secondary;
      clip-path: none;
      font-size: 1.25em;

      &::before {
        margin-left: -5px;
      }
    }
  }

  &__burger {
    font-size: px-to-em(32px);
    width: px-to-em(65px, 32px);
    max-width: px-to-em(65px, 32px);
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    border: 0;

    &::before,
    &::after {
      content: "";
      position: absolute;
      background-color: $color-white;
      height: 5px;
      right: 10px;
      border-radius: 1px;
      transition: $medium-transition;
      transform-origin: center center;
    }

    &::before {
      width: 60%;
      top: 35%;
    }

    &::after {
      width: 45%;
      bottom: 35%;
    }

    &--active {
      &::before,
      &::after {
        background-color: $color-secondary;
        width: px-to-em(20px);
        border-radius: 3px;
        right: 50%;
        height: 6px;
      }

      &::before {
        top: 50%;
        transform: translate(50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(50%, 50%) rotate(-45deg);
        bottom: 50%;
      }
    }
  }

  &__submenu-inner,
  &__third-menu-inner {
    padding: 0;
  }

  &__item,
  &__submenu-item,
  &__third-menu-item,
  &__fourth-menu-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 px-to-em($gap-xxs);
    position: relative;
    cursor: pointer;
  }

  &__item,
  &__submenu-item {
    background: $color-white;
  }

  &__item {
    height: px-to-em(70px);
  }

  &__link {
    @extend %base-text--default;
  }

  &__submenu-item,
  &__third-menu-item {
    height: px-to-em(55px);
  }

  &__fourth-menu-item {
    height: px-to-em(45px);
  }

  &__submenu-header {
    padding: px-to-em($gap-s) px-to-em($gap-xxs);
    background-color: $color-primary;
  }

  &__back {
    color: $color-san-marino-blue;
    display: flex;
    align-items: center;
    @extend %base-text--default;
    height: px-to-em(20px);
    margin-bottom: px-to-em($gap-xs);
    cursor: pointer;

    .icon {
      margin-right: 2px;
    }
  }

  &__submenu-title {
    color: $color-white;
    font-size: px-to-rem(18px);
    margin-bottom: 0;
  }

  &__third-strate {
    background-color: $color-primary;
    z-index: 40;

    #{$base-class}__submenu-title {
      text-decoration: underline;
      text-transform: unset;
    }

    #{$base-class}__submenu-header {
      border-top: 1px solid $color-white;
      border-bottom: 1px solid $color-white;
    }

    #{$base-class}__item-inner {
      color: $color-secondary;
      @extend %base-text--default;
    }
  }

  &__fourth-strate {
    background-color: $color-primary;
    z-index: 50;

    #{$base-class}__submenu-title {
      text-decoration: underline;
      text-transform: unset;
    }

    #{$base-class}__submenu-header {
      border-top: 1px solid $color-white;
    }

    #{$base-class}__see-all {
      text-decoration: underline;
    }
  }

  .contact-mention {
    position: absolute;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: $menu-mobile-contact-height;
    padding: px-to-em(5px) 0;
    border-top: 2px solid $color-white;
    background: $color-primary;
    pointer-events: all;

    &__line {
      flex-direction: column;
      align-items: flex-start;
    }

    &__opening-hours {
      font-size: px-to-em(12px);
    }
  }
}
