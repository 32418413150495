.pagination {
  @extend .gui-container;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 2em;

  &__amount {
    margin: auto;
  }

  &__button {
    $height: px-to-em(30px);

    line-height: $height;
    height: $height;
    color: $color-primary;

    & + & {
      margin-left: px-to-em(12px);
    }

    &--selected,
    &--prev,
    &--next {
      background-color: $color-athens-gray;
    }

    &--prev {
      &::before {
        @extend %icon--angle-left;
      }
    }

    &--next {
      &::before {
        @extend %icon--angle-right;
      }
    }

    &--prev,
    &--next {
      $size: px-to-em(40px);

      display: flex;
      align-items: center;
      justify-content: center;
      height: $size;
      width: $size;
      border: 1px solid $color-alto;
      border-radius: 10px;
      transition: background-color $default-transition-duration;

      &:hover {
        background-color: darken($color-athens-gray, 1%);
      }

      &::before {
        @extend %icon;

        font-size: 20px;
        color: $color-primary;
      }
    }

    &--selected,
    &--dots {
      pointer-events: none;
    }

    &--selected {
      padding: 0 px-to-em(3px);
    }

    &--dots {
      &::before {
        content: "…";
      }
    }
  }

  &__limiter {
    flex-grow: 1;
    text-align: center;
    width: 100%;
    margin-top: 1em;

    @include breakpoint($breakpoint-tablet) {
      flex-grow: 0;
      width: auto;
      margin-top: 0;
      margin-left: auto;
    }

    .form-input {
      &--select {
        &::before {
          top: auto;
        }
      }

      &__input {
        $height: px-to-em(40px);

        height: $height;
        line-height: $height;
        min-width: px-to-em(80px);
        border: 1px solid $color-alto;
        background-color: $color-athens-gray;
        cursor: pointer;
        transition: background-color $default-transition-duration;

        &:hover {
          background-color: darken($color-athens-gray, 1%);
        }
      }
    }
  }
}
