.image-text-button {
  height: px-to-rem(350px);

  &__overlay {
    background-color: $color-polar;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
    }

    &::before {
      z-index: 1;
      left: 0;
      right: 0;
      background-color: $color-boticelli;
      clip-path: polygon(0 0, 30% 0, 0 60%, 0 50%);
      @include breakpoint($breakpoint-switch) {
        clip-path: polygon(
          0 0,
          px-to-rem(275px) 0,
          px-to-rem(60px) 100%,
          0 100%
        );
      }
    }

    &::after {
      z-index: 2;
      right: 0;
      left: 0;
      background-color: transparentize($color-link-water, 0.35);
      mix-blend-mode: multiply;
      clip-path: polygon(20% 0, 100% 0, 100% 100%, 65% 100%);
      @include breakpoint($breakpoint-switch) {
        clip-path: polygon(
          px-to-rem(210px) 0,
          100% 0,
          100% 100%,
          px-to-rem(425px) 100%
        );
      }
    }
  }

  &__img {
    display: none;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: $font-size-xxxl;
  }

  &__title,
  &__description {
    color: $color-black;
    font-weight: map-get($font-weight, light);
  }

  &__description {
    @extend %base-text--small;
    font-family: $font-default;
    text-align: center;
  }

  &__content {
    position: relative;
    z-index: 3;
    grid-gap: 1em;
  }
}
