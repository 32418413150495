$base-class: ".banner-image-text-tabs";

#{$base-class} {
  &--higher {
    padding: px-to-em($gap-s) 0;
    background-color: $color-athens-gray;

    @include breakpoint($breakpoint-switch) {
      padding: px-to-em($gap-xl) 0 px-to-em(120px);
      height: px-to-em(575px);
    }

    #{$base-class}__texts-container {
      padding-left: 0;
      padding-right: px-to-em($gap-m);
    }
  }

  &--grey {
    background-color: $color-athens-gray;

    #{$base-class}__hexagon {
      &::before {
        background-color: $color-athens-gray;
      }
    }
  }

  &__container {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include breakpoint($breakpoint-switch) {
      flex-direction: row;
      padding-top: px-to-em($gap-xxl);
      padding-bottom: px-to-em($gap-xxl);
      height: 100%;
    }

    &--right-img {
      flex-direction: column-reverse;
      @include breakpoint($breakpoint-switch) {
        flex-direction: row-reverse;
      }
    }
  }

  &__imgs-container {
    position: relative;
    margin: 0 auto;
    min-height: px-to-em(230px);
  }

  &__standard-img {
    margin: 0 auto;
    width: 90%;
    display: block;
    margin-bottom: px-to-em($gap-m);

    @include breakpoint($breakpoint-m) {
      max-height: px-to-em(410px);
      max-width: none;
      width: auto;
    }
  }

  &__texts-container {
    flex-grow: 1;
  }

  &__wrapper {
    color: $color-primary;
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    position: relative;
  }

  .main-title {
    &#{$base-class}__title-no-hex {
      &--mobile {
        @include breakpoint($breakpoint-switch) {
          display: none;
        }
      }

      &--desktop {
        display: none;
        @include breakpoint($breakpoint-switch) {
          display: block;
        }
      }
    }
  }

  &__content {
    z-index: 2;
    width: 100%;
    color: $color-default;

    @include breakpoint($breakpoint-switch) {
      opacity: 0;
      position: absolute;
      top: 60px;
      left: 0;
      padding: 0 px-to-em($gap-m);
    }
  }

  &__keyword {
    position: relative;
    margin-right: 20px;
  }

  &__checkbox {
    display: none;

    &:checked {
      & + #{$base-class}__label-wrapper {
        & > #{$base-class}__label {
          @extend %link-hover-animation--hover-switch;
        }

        & + #{$base-class}__content {
          opacity: 1;
        }
      }
    }
  }

  &__label-wrapper {
    margin: 0;
  }

  &__label {
    @extend %link-hover-animation;

    position: relative;
    z-index: 5;
    cursor: pointer;
    color: inherit;
    font-size: $font-size-l;
    margin-right: px-to-em($gap-m);
    font-weight: map-get($font-weight, bold);
    transition: color $default-transition-duration;

    @include breakpoint($breakpoint-switch) {
      margin-bottom: 0;
      font-size: $font-size-m;
      font-weight: initial;
    }
  }
}
