$base-class: ".banner-image-text-tabs";

#{$base-class} {
  &__container {
    background-color: $color-spring-wood;
    width: 100%;
  }

  &__texts-container,
  &__imgs-block {
    width: 50%;
    padding: 0 2em;
    margin: 0;
  }

  &__imgs-block {
    max-height: px-to-em(350px);
  }

  &__imgs-container {
    height: 100%;
  }

  &__main-img {
    width: 100%;
    height: 100%;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__hexagon {
    display: none;
  }

  &__small-img {
    display: none;
  }

  &__texts-container {
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
  }

  .main-title {
    &__title {
      display: flex;
      margin-bottom: 0;
      text-transform: uppercase;
      font-family: $font-secondary;
      font-size: px-to-em($font-size-xl);

      &::before {
        content: "";
        background-color: $color-primary;
        width: 10px;
        transform: translateY(-2px);
        margin: 0 5px 0 0;
      }
    }
  }

  &__label-wrapper {
    font-size: px-to-em($font-size-xl);
    font-family: $font-secondary;
  }

  &__checkbox {
    &:checked {
      & + #{$base-class}__label-wrapper {
        & > #{$base-class}__label {
          color: $color-black;
          @include breakpoint($breakpoint-switch) {
            font-weight: map-get($font-weight, normal);
          }
          &::after {
            display: none;
          }
        }
      }
    }
  }

  &__content {
    font-family: $font-default;
    transform: translateY(-1.5em);
    @include breakpoint($breakpoint-switch) {
      padding: 0;
    }
  }
}
