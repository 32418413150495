.product-main-informations {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0 1em;
  margin: 0 0 2em 0;

  @include breakpoint($breakpoint-switch) {
    padding: 0;
    margin: 2em auto;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    @include breakpoint($breakpoint-switch) {
      flex-direction: row;
    }
  }

  &__img,
  &__container {
    width: 100%;

    @include breakpoint($breakpoint-switch) {
      width: 50%;
      height: 100%;
    }
  }

  &__img {
    max-height: px-to-em(240px);
    @include breakpoint($breakpoint-switch) {
      max-height: px-to-em(400px);
    }
    object-fit: contain;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    font-family: $font-secondary;
    font-size: $size-xxl;
    font-weight: map-get($font-weight, semi-bold);
    margin-bottom: 0;
  }

  &__button {
    padding-top: 1em;
  }

  &__short-description {
    ul {
      list-style-type: disc;
      padding: px-to-rem(20px);
    }
  }
}
