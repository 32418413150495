/**
This component is a part of Magento. It should be hidden by default.
 */

.cookie-status-message {
  display: none;
}

.message.global.noscript,
.notice.global.site.local_storage {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background-color: $color-athens-gray;
  box-shadow: 0 0 1000px 0 #000000;
  padding: px-to-em(50px);
  max-width: 96%;
  z-index: 500;
}
