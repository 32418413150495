$base-class: ".menu";
#{$base-class} {
  $menu-box-shadow: 0 5px 10px #00000034;
  display: flex;
  box-shadow: $menu-box-shadow;
  position: relative;
  background-color: $color-white;

  body.menu-opened & {
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: #000000;
      opacity: 0.6;
      height: 200vh;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    height: px-to-em(70px);

    &:hover {
      #{$base-class} {
        &__item-inner {
          #{$base-class} {
            &__chevron {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  &__chevron {
    display: inline-block;
    margin-left: 5px;
    transform-origin: center;
    transition: transform $default-transition-duration;
  }

  &__item-inner {
    display: flex;
    align-items: center;

    color: $color-primary;
    transition: color $default-transition-duration;

    &--n1 {
      text-align: center;
    }

    &--n2 {
      text-transform: uppercase;
      padding: px-to-em(10px, $base-font-size) px-to-em(50px, $base-font-size)
        px-to-em(10px, $base-font-size) 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &--n3 {
      padding: px-to-em(10px, $base-font-size) 0;

      &:hover {
        color: $color-cadet-blue;
      }
    }

    &--button {
      height: 100%;
    }
  }

  &__submenu {
    background-color: $color-white;
    position: absolute;
    z-index: $z-index-menu;
    top: 100%;
    left: 0;
    right: 0;
    height: 0;
    overflow: hidden;

    &--opened {
      height: auto;
    }
  }

  &__submenu-list {
    position: relative;
    min-height: px-to-em(200px);
  }

  &__n2-item {
    width: 35%;
  }

  &__n2-chevron {
    margin-left: px-to-em(30px);
  }

  &__sub-submenu {
    padding: px-to-em(25px) 0 px-to-em(25px) px-to-em(50px);
    position: absolute;
    top: 0;
    left: 35%;
    width: 65%;
    display: none;
    height: 100%;
    overflow: auto;

    &--opened {
      display: block;
    }
  }
}
