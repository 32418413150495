.store-locator-store-card {
  @extend %base-text--default;

  &__name {
    font-size: px-to-rem(18px);
    font-weight: map-get($font-weight, bold);
    margin: 0;
  }

  &__distance,
  &__address,
  &__phone-number {
    font-size: px-to-rem(16px);
  }

  &__distance {
    font-weight: map-get($font-weight, bold);
  }

  &__mail-link,
  &__phone-number-link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
