$base-class: ".menu";
#{$base-class} {
  flex-direction: column;
  z-index: 99;
  border-top: 1px solid $color-athens-gray;

  @include breakpoint($breakpoint-switch) {
    flex-direction: row;
  }

  &__item-image {
    display: none;
  }

  &__group-buttons {
    display: none;
    justify-content: center;
    width: 100%;
    height: fit-content;
    padding: 0 1em px-to-em(25px);
  }

  &__item {
    border-bottom: solid 2px $color-athens-gray;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @include breakpoint($breakpoint-switch) {
      flex-direction: row;
      border-bottom: none;
      height: px-to-rem(50px);
      align-items: center;
    }

    &:first-of-type {
      #{$base-class}__group-buttons {
        display: flex;
        flex-direction: column;
        gap: px-to-rem(10px);
        margin-top: px-to-rem(10px);

        @include breakpoint($breakpoint-switch) {
          flex-direction: row;
          gap: px-to-rem(32px);
          margin-top: 0;
        }
      }

      #{$base-class}__submenu {
        width: 100%;

        @include breakpoint($breakpoint-switch) {
          width: auto;
          border-top: solid 1px $color-ebb;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      #{$base-class}__submenu-list {
        overflow: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: unset;
        height: unset;
        max-width: 100%;
        padding: px-to-rem(25px);

        &::before {
          display: none;
        }
      }

      #{$base-class}__n2-item {
        height: 100%;
        padding: 0 2em 0 1em;
        width: unset;
        min-width: px-to-rem(200px);

        &:not(:last-child) {
          border-right: solid 1px $color-athens-gray;
        }

        #{$base-class}__item-inner {
          display: flex;
          flex-direction: column;
          gap: 0.5em;
          height: unset;
          padding: unset;

          #{$base-class}__item-text {
            font-family: $font-secondary;
            font-size: px-to-rem(20px);
            font-weight: map-get($font-weight, "normal");
            color: $color-black;
            position: relative;
            padding-left: 1em;

            &::before {
              content: "";
              width: px-to-em(7px);
              height: px-to-em(7px);
              clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
              background-color: $color-black;
              transform: translateY(-2px) rotate(90deg);
              position: absolute;
              bottom: px-to-rem(10px);
              left: 0;
            }
          }

          &--n2 {
            align-items: flex-start;
            margin: 0;
          }

          &::before {
            content: "";
            width: 0;
            margin: 0;
          }
        }
      }

      #{$base-class}__item-image {
        display: block;
        height: px-to-rem(150px);
        object-fit: cover;
      }
    }

    &:hover {
      #{$base-class}__item-inner {
        color: $color-black;
      }

      & >,
      #{$base-class}__item-inner--button {
        #{$base-class}__item-inner {
          color: $color-tuatara;
        }
      }
    }
  }

  &__submenu {
    position: relative;
    top: 0;

    @include breakpoint($breakpoint-switch) {
      position: absolute;
      top: 100%;
    }
  }

  &__item-inner {
    font-size: px-to-rem(16px);
    font-weight: map-get($font-weight, "medium");
    height: px-to-rem(40px);
    padding: 0 px-to-rem(20px);

    &--n1 {
      min-height: px-to-rem(50px);
      border-right: none;
      text-transform: capitalize;

      @include breakpoint($breakpoint-switch) {
        min-height: unset;
        height: px-to-rem(30px);
        border-right: solid 1px $color-athens-gray;
      }
    }

    &--n2 {
      font-size: px-to-rem(20px);
      justify-content: flex-start;
      align-items: center;
      font-family: $font-secondary;
      cursor: pointer;
      height: fit-content;
      width: 100%;

      @include breakpoint($breakpoint-switch) {
        height: 50px;
      }
    }
  }

  &__n2-item {
    width: 100%;

    @include breakpoint($breakpoint-switch) {
      width: px-to-rem(300px);
    }

    &:hover {
      #{$base-class}__item-inner--n2 {
        color: $color-tuatara;
      }
    }
  }

  &__submenu:not(:first-child) {
    #{$base-class}__submenu--opened {
      max-height: px-to-rem(250px);
    }
  }

  &__submenu-list {
    max-width: unset;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: px-to-rem(5px);

    @include breakpoint($breakpoint-switch) {
      padding: px-to-rem(5px) 0;
      flex-wrap: wrap;
      height: px-to-rem(250px);
      width: fit-content;
    }
  }

  &__n2-chevron {
    display: none;
  }

  &__sub-submenu {
    display: none;
  }
}
