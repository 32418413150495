.wysiwyg {
  &--revert-base {
    > {
      @extend %wysiwyg-revert-base;
    }

    > *:not(.widget):not(.store-locator-large) {
      @extend .gui-container;
    }
  }

  > {
    @extend %wysiwyg-headings;
    @extend %wysiwyg-links;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      padding: 0 1em;
    }

    iframe {
      display: block;
    }
  }

  > img {
    @extend .image-cut !optional;
  }

  .widget {
    img {
      margin: 0;
    }
  }

  > *:not(.widget):not(.store-locator-large) {
    @extend %wysiwyg-block;
    @extend %wysiwyg-headings;
    @extend %wysiwyg-links;
    @extend .gui-container;

    img {
      @extend .image-cut !optional;

      $margin: 1em;

      max-width: calc(100% - #{2 * $margin});
      margin: $margin;

      @include breakpoint($breakpoint-tablet) {
        $margin: 2em;

        max-width: calc(100% - #{2 * $margin});
        margin: $margin;
      }

      &.product-tabs-table__image {
        max-width: 80%;
        margin: 0;
      }
    }
  }

  ul {
    list-style: inside;
  }
}
