%medium-cut {
  clip-path: polygon(
    calc(0% + 50px) 0,
    100% 0,
    100% calc(100% - 50px),
    calc(100% - 50px) 100%,
    0 100%,
    0 calc(0% + 50px)
  );
}

%hexagon-cut {
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}
