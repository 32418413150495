%separator {
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    background-color: $color-athens-gray;
    transform: translate3d(-50%, 0, 0);
  }

  &::before {
    top: 0;
    max-width: px-to-em(500px);
    width: 100%;
    height: 1px;
    transform: translate3d(-50%, 0, 0);
  }

  &::after {
    @extend %hexagon-cut;

    top: px-to-em(-7px);
    width: px-to-em(14px);
    height: px-to-em(14px);
  }
}
