.banner-text-tabs {
  &--light {
    color: $color-black;
  }

  &__container {
    min-height: px-to-em(270px);
    background-color: $color-humming-bird;
    padding: px-to-em(55px) 0 px-to-em($gap-l) px-to-em(110px);
  }

  &__wrapper {
    grid-row-gap: 0.5em;
    max-width: 100%;
    @include breakpoint($breakpoint-tablet) {
      max-width: 60%;
    }
  }

  &__img {
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 5% 100%);
    height: calc(100% + 70px);
    right: 0;
    top: 0;
    max-width: px-to-rem(400px);
  }

  &__title {
    font-family: $font-secondary;
    font-size: px-to-em(56px);
    margin-bottom: px-to-rem(20px);
  }

  &__content {
    font-family: $font-default;
  }

  &__label {
    font-family: $font-secondary;
    font-size: $font-size-xl;
    color: $color-primary;
  }

  &__checkbox {
    &:checked + label {
      color: inherit;
      &::before {
        content: "//";
        margin-right: px-to-rem(5px);
        transition: $medium-transition;
      }
    }
  }
}
