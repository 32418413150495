$base-class: ".main-header-part";
#{$base-class} {
  background-color: $color-white;
  gap: 0.5em;
  @include breakpoint($breakpoint-switch) {
    position: fixed;
    width: 100%;
    top: px-to-rem(30px);
    z-index: 100;
  }

  &__contact {
    opacity: 0;

    .contact-mention__line {
      display: none;
    }
  }

  .input-field {
    &__input {
      border: 2px solid transparentize($color-nobel, 0.8);
      height: em-to-rem(3em);
    }

    &__button {
      display: flex;
      justify-content: center;
      background-color: $color-primary;
      color: $color-white;
      margin: 0;
      padding: 0;
      width: em-to-rem(3em);
      height: em-to-rem(3em);
      &::before {
        font-size: px-to-rem(20px);
      }
    }
  }

  &__logo-image {
    height: px-to-em(24.8px);

    @include breakpoint($breakpoint-switch) {
      height: px-to-em(33.6px);
    }
  }
}
