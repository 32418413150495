.pre-header {
  background-color: $color-primary;
  justify-content: flex-end;
  align-items: center;
  padding: px-to-em(23px) px-to-em(10px);

  &__item {
    display: flex;
    align-items: center;
    height: px-to-em(40px);
    margin-right: px-to-em(20px, $base-font-size);
  }

  &__link {
    $font-size: 14px;
    color: transparent;
    font-size: px-to-em($font-size);
    font-weight: map-get($font-weight, bold);
    position: relative;

    &--lang {
      margin-right: px-to-em(10px, $base-font-size);
    }

    &::before {
      content: attr(data-text);
      font-weight: map-get($font-weight, normal);
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      left: 0;
      color: $color-white;
    }

    &:hover {
      color: $color-white;

      &::before {
        color: transparent;
      }
    }
  }

  @include breakpoint($breakpoint-switch) {
    &__item {
      height: px-to-em(30px);
    }

    &__link {
      &--lang {
        display: none;
      }
    }

    background-color: $color-black;
    display: flex;
    padding: 0 px-to-em(10px) 0 0;
  }
}
