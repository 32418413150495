.flash-messages {
  position: fixed;
  z-index: 500;
  bottom: 50px;
  left: 10%;
  right: 10%;

  &__item {
    width: 100%;
    margin: 10px 0;
    background-color: #ff9966;
    box-shadow: 0 0 10px 1px #ff9966;
    color: $color-white;
    animation: flash 0.5s forwards;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--success {
      background-color: #20c7a8;
      box-shadow: 0 0 10px 1px #20c7a8;
    }

    &--error {
      background-color: #ec625f;
      box-shadow: 0 0 10px 1px #ec625f;
    }
  }

  &__inner {
    display: flex;
    flex: 1;
    align-items: center;

    a {
      text-decoration: underline;
      color: $color-white;
      transition: color $default-transition-duration;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__button {
    cursor: pointer;
    width: px-to-em(40px);
    height: px-to-em(40px);
    border-radius: px-to-em(20px);
    margin-left: auto;
    background: none;
    border: solid 1px $color-white;
    color: $color-white;
    transition: transform $default-transition-duration;
    transform-origin: center;

    &:hover {
      transform: rotate(180deg);
    }
  }
}

@keyframes flash {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
