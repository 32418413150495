.store-locator {
  width: 100%;
  height: px-to-em(250px);
  display: flex;
  flex-direction: column;
  background-image: url("../images/map.png");
  background-size: cover;
  justify-content: center;

  &__heading {
    text-align: center;

    @include breakpoint($breakpoint-desktop) {
      font-size: px-to-em(25px);
      font-weight: map-get($font-weight, bold);
    }
  }

  &__input-group {
    width: px-to-em(250px);
    margin: 0 auto;

    @include breakpoint($breakpoint-desktop) {
      width: px-to-em(475px);
    }
  }
}
