// # Font variables
//
// Font variables SHOULD be prefixed with 'font-' to identify them easier
// and to be able to filter them when typing in your favorite IDE/editor's
// auto-completion.
$font-system: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica,
  Arial, sans-serif;

$font-calibri: Calibri;
$font-lato: Lato;
$font-teko: "Teko";
$font-montserrat: "Montserrat";
$font-icons: "font-icons";

// ## Font weights
$font-weight: (
  light: 200,
  normal: 400,
  medium: 500,
  semi-bold: 600,
  bold: 800,
  black: 900,
);
