.breadcrumbs {
  @extend .gui-container;

  display: none;
  color: $color-primary;

  @include breakpoint($breakpoint-switch) {
    display: block;
  }

  &__container {
    display: none;
    padding: px-to-em(10px) 0 px-to-em(15px) 0;
    // Prevent layout shift when breadcrumbs are loading on product page
    min-height: px-to-em(50px);

    @include breakpoint($breakpoint-switch) {
      display: block;
    }
  }

  &__item {
    display: inline;

    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }

  &__link {
    @extend %link-hover-animation;

    position: relative;

    &:hover {
      @extend %link-hover-animation--hover;
    }
  }

  &__current {
    font-weight: map-get($font-weight, bold);
  }
}
