/// Base btn component
///
/// Usage:
///
/// ```html
/// <button class="base-btn base-btn--default" type="button">
///   Lorem ipsum
/// </button>
///
/// <a href="#" class="base-btn base-btn--default base-btn--block">
///   Lorem ipsum
/// </a>
///
/// <a href="#" class="base-btn base-btn--primary">
///   Lorem ipsum
/// </a>
/// ```
#{$base-btn-block-selector} {
  $block-selector: &;

  @extend %base-btn;

  &--default {
    @extend %base-btn--default;
  }

  &--primary {
    @extend %base-btn--primary;

    @include hover-focus-within-workaround() {
      background-color: lighten($color-primary, 10%);
    }
  }

  &--secondary {
    @extend %base-btn--inverted;

    @include hover-focus-within-workaround() {
      background-color: $color-secondary;
    }
  }

  &--tertiary {
    @extend %base-btn--tertiary;
    @include hover-focus-within-workaround() {
      background-color: darken($color-thunderbird, 10%);
    }
  }

  &--with-icon {
    @extend %base-btn--with-icon;
  }

  &--block {
    @extend %base-btn--block;
  }

  &--link {
    @extend %base-btn--link;
  }

  &[disabled],
  &#{$block-selector + "--disabled"} {
    @extend %base-btn--disabled;
  }

  &--icon {
    @extend %base-btn--icon;
  }

  &--discreet {
    @extend %base-btn--discreet;
  }

  &--small {
    $height: 40px;

    height: $height;
    line-height: $height;
  }
}
