$base-class: ".main-header-part";
#{$base-class} {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: px-to-em(5px) px-to-em(10px) px-to-em(5px) px-to-em(20px);

  &__search {
    width: 40%;

    & > * {
      max-width: px-to-em(410px);
    }
  }

  &__contact {
    width: 40%;
    display: flex;
    justify-content: flex-end;
  }

  &__logo-image {
    width: auto;
  }
}
