$base-class: ".custom-checkbox";

#{$base-class} {
  &__initial {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + label #{$base-class}__checkbox {
      background-color: $color-primary;
    }

    &:checked + label #{$base-class}__checkbox #{$base-class}__checkmark {
      opacity: 1;
    }
  }

  &__label {
    cursor: pointer;
  }

  &__checkbox {
    border-radius: 5px;
    background-color: white;
    width: px-to-em(20px);
    height: px-to-em(20px);
    display: inline-block;
    position: relative;
  }

  &__checkmark {
    height: px-to-em(16px);
    width: px-to-em(16px);
    position: absolute;
    opacity: 0;
  }

  .mage-error {
    font-family: $font-lato;
    font-weight: normal;
    font-size: 1rem;
    color: $color-monza;

    + #{$base-class} {
      &__label {
        #{$base-class} {
          &__checkbox {
            border-color: $color-monza;
          }
        }
      }
    }
  }
}
