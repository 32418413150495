$base-class: ".news-card";

#{$base-class} {
  padding-bottom: 1em;
  border-bottom: 1px solid $color-ebb;
  @include breakpoint($breakpoint-s) {
    padding-bottom: 0;
    border-bottom: none;
  }

  &__content-wrapper {
    align-items: center;
  }

  &__title {
    font-family: $font-secondary;
    font-size: px-to-em($font-size-xl);
    font-weight: map-get($font-weight, medium);
    line-height: 1;
    margin-bottom: px-to-rem(5px);

    &--centered {
      text-align: center;
    }
  }

  &__text {
    font-family: $font-default;
    font-size: px-to-em($font-size-m);
    font-weight: map-get($font-weight, normal);
    white-space: pre-wrap;

    @include breakpoint($breakpoint-tablet) {
      display: unset;
      white-space: unset;
    }

    &--centered {
      display: block;
      text-align: center;
    }
  }

  &__overlay {
    background-color: rgba($color-black, 0.2);
  }
}
