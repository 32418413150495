$base-class: ".product-card";

#{$base-class} {
  min-height: px-to-em(340px);
  overflow: hidden;
  position: relative;
  padding-bottom: px-to-em($gap-xs);

  @include breakpoint($breakpoint-tablet) {
    max-height: px-to-em(400px);
  }
  &:hover #{$base-class}__overlay {
    opacity: 1;
  }
  &__img {
    width: auto;
    height: px-to-em(220px);
    display: block;
    object-fit: cover;
    margin: px-to-em($gap-s) auto px-to-em($gap-xs);
    @include breakpoint($breakpoint-tablet) {
      height: px-to-em(250px);
      margin: px-to-em($gap-s) auto px-to-em($gap-l);
    }
  }
  &__overlay {
    background-color: transparentize($color-primary, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: px-to-em($gap-xxxs);
    opacity: 0;
    transition: $medium-transition;
  }

  &__title {
    @extend %card-title;
  }

  &__text {
    font-size: $font-size-s;
    margin: 0;
    text-align: center;
  }

  &--small {
    width: px-to-em(230px);
    min-height: px-to-em(300px);

    @include breakpoint($breakpoint-tablet) {
      width: px-to-em(270px);
      min-height: px-to-em(340px);
    }

    #{$base-class}__img {
      height: px-to-em(170px);
      margin: px-to-em($gap-s) auto;
      @include breakpoint($breakpoint-tablet) {
        height: px-to-em(200px);
      }
    }
  }
}
