.custom-checkbox {
  display: flex;

  &__label {
    display: flex;
  }

  &__initial {
    display: none;
  }

  &__checkbox {
    border: 2px solid $color-black;
    transform: translateY(5px);
    margin-right: 1em;
  }

  &__checkmark {
    top: 0;
    left: 0;
    vertical-align: super;
    position: unset;
  }

  &__redirect-link {
    color: $color-picton-blue;
    text-decoration: underline;
  }
}
