@charset "UTF-8";

// # Font-faces
//
// This file only contains font faces and is included once at the beginning
// of the main entry file.
//
// ```
// @font-face {
//   font-family: $font-name-example;
//   src: url("../fonts/example-font.eot");
//   src: url("../fonts/example-font.eot?#iefix") format("embedded-opentype"),
//     url("../fonts/example-font.woff2") format("woff2"),
//     url("../fonts/example-font.woff") format("woff"),
//     url("../fonts/example-font.ttf") format("truetype"),
//     url("../fonts/example-font.svg#example-font") format("svg");
//   font-weight: map-get($font-weight-example, medium);
//   font-style: normal;
// }
// ```

// Uncomment following font faces only if they are used

@font-face {
  font-family: $font-icons;
  src: url("../fonts/font-icons.eot");
  src: url("../fonts/font-icons.eot?#iefix") format("embedded-opentype"),
    url("../fonts/font-icons.woff") format("woff"),
    url("../fonts/font-icons.ttf") format("truetype"),
    url("../fonts/font-icons.svg#font-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

//@font-face {
//  font-family: $font-calibri;
//  src: url("../fonts/Calibri.svg");
//  src: url("../fonts/Calibri.svg?#iefix") format("embedded-opentype"),
//  url("../fonts/Calibri.woff2") format("woff2"),
//  url("../fonts/Calibri.woff") format("woff"),
//  url("../fonts/Calibri.ttf") format("truetype"),
//  url("../fonts/Calibri.svg#example-font") format("svg");
//  font-weight: map-get($font-weight, normal);
//  font-style: normal;
//}
@font-face {
  font-family: $font-calibri;
  src: url("../fonts/Calibri-Bold.svg");
  src: url("../fonts/Calibri-Bold.svg?#iefix") format("embedded-opentype"),
    url("../fonts/Calibri-Bold.woff2") format("woff2"),
    url("../fonts/Calibri-Bold.woff") format("woff"),
    url("../fonts/Calibri-Bold.ttf") format("truetype"),
    url("../fonts/Calibri-Bold.svg#example-font") format("svg");
  font-weight: map-get($font-weight, bold);
  font-style: normal;
}

//@font-face {
//  font-family: $font-calibri;
//  src: url("../fonts/Calibri-BoldItalic.svg");
//  src: url("../fonts/Calibri-BoldItalic.svg?#iefix") format("embedded-opentype"),
//  url("../fonts/Calibri-BoldItalic.woff2") format("woff2"),
//  url("../fonts/Calibri-BoldItalic.woff") format("woff"),
//  url("../fonts/Calibri-BoldItalic.ttf") format("truetype"),
//  url("../fonts/Calibri-BoldItalic.svg#example-font") format("svg");
//  font-weight: map-get($font-weight, bold);
//  font-style: italic;
//}
@font-face {
  font-family: $font-calibri;
  src: url("../fonts/Calibri-Italic.svg");
  src: url("../fonts/Calibri-Italic.svg?#iefix") format("embedded-opentype"),
    url("../fonts/Calibri-Italic.woff2") format("woff2"),
    url("../fonts/Calibri-Italic.woff") format("woff"),
    url("../fonts/Calibri-Italic.ttf") format("truetype"),
    url("../fonts/Calibri-Italic.svg#example-font") format("svg");
  font-weight: map-get($font-weight, normal);
  font-style: italic;
}

@font-face {
  font-family: $font-calibri;
  src: url("../fonts/Calibri-Light.svg");
  src: url("../fonts/Calibri-Light.svg?#iefix") format("embedded-opentype"),
    url("../fonts/Calibri-Light.woff2") format("woff2"),
    url("../fonts/Calibri-Light.woff") format("woff"),
    url("../fonts/Calibri-Light.ttf") format("truetype"),
    url("../fonts/Calibri-Light.svg#example-font") format("svg");
  font-weight: map-get($font-weight, light);
  font-style: normal;
}

//@font-face {
//  font-family: $font-calibri;
//  src: url("../fonts/Calibri-LightItalic.svg");
//  src: url("../fonts/Calibri-LightItalic.svg?#iefix") format("embedded-opentype"),
//  url("../fonts/Calibri-LightItalic.woff2") format("woff2"),
//  url("../fonts/Calibri-LightItalic.woff") format("woff"),
//  url("../fonts/Calibri-LightItalic.ttf") format("truetype"),
//  url("../fonts/Calibri-LightItalic.svg#example-font") format("svg");
//  font-weight: map-get($font-weight, light);
//  font-style: italic;
//}

@font-face {
  font-family: $font-lato;
  src: url("../fonts/Lato-Black.svg");
  src: url("../fonts/Lato-Black.svg?#iefix") format("embedded-opentype"),
    url("../fonts/Lato-Black.woff2") format("woff2"),
    url("../fonts/Lato-Black.woff") format("woff"),
    url("../fonts/Lato-Black.ttf") format("truetype"),
    url("../fonts/Lato-Black.svg#example-font") format("svg");
  font-weight: map-get($font-weight, black);
  font-style: normal;
}

//@font-face {
//  font-family: $font-lato;
//  src: url("../fonts/Lato-BlackItalic.svg");
//  src: url("../fonts/Lato-BlackItalic.svg?#iefix") format("embedded-opentype"),
//  url("../fonts/Lato-BlackItalic.woff2") format("woff2"),
//  url("../fonts/Lato-BlackItalic.woff") format("woff"),
//  url("../fonts/Lato-BlackItalic.ttf") format("truetype"),
//  url("../fonts/Lato-BlackItalic.svg#example-font") format("svg");
//  font-weight: map-get($font-weight, black);
//  font-style: italic;
//}
@font-face {
  font-family: $font-lato;
  src: url("../fonts/Lato-Bold.svg");
  src: url("../fonts/Lato-Bold.svg?#iefix") format("embedded-opentype"),
    url("../fonts/Lato-Bold.woff2") format("woff2"),
    url("../fonts/Lato-Bold.woff") format("woff"),
    url("../fonts/Lato-Bold.ttf") format("truetype"),
    url("../fonts/Lato-Bold.svg#example-font") format("svg");
  font-weight: map-get($font-weight, bold);
  font-style: normal;
}

//@font-face {
//  font-family: $font-lato;
//  src: url("../fonts/Lato-BoldItalic.svg");
//  src: url("../fonts/Lato-BoldItalic.svg?#iefix") format("embedded-opentype"),
//  url("../fonts/Lato-BoldItalic.woff2") format("woff2"),
//  url("../fonts/Lato-BoldItalic.woff") format("woff"),
//  url("../fonts/Lato-BoldItalic.ttf") format("truetype"),
//  url("../fonts/Lato-BoldItalic.svg#example-font") format("svg");
//  font-weight: map-get($font-weight, bold);
//  font-style: italic;
//}
//@font-face {
//  font-family: $font-lato;
//  src: url("../fonts/Lato-Italic.svg");
//  src: url("../fonts/Lato-Italic.svg?#iefix") format("embedded-opentype"),
//  url("../fonts/Lato-Italic.woff2") format("woff2"),
//  url("../fonts/Lato-Italic.woff") format("woff"),
//  url("../fonts/Lato-Italic.ttf") format("truetype"),
//  url("../fonts/Lato-Italic.svg#example-font") format("svg");
//  font-weight: map-get($font-weight, normal);
//  font-style: italic;
//}
@font-face {
  font-family: $font-lato;
  src: url("../fonts/Lato-Light.svg");
  src: url("../fonts/Lato-Light.svg?#iefix") format("embedded-opentype"),
    url("../fonts/Lato-Light.woff2") format("woff2"),
    url("../fonts/Lato-Light.woff") format("woff"),
    url("../fonts/Lato-Light.ttf") format("truetype"),
    url("../fonts/Lato-Light.svg#example-font") format("svg");
  font-weight: map-get($font-weight, light);
  font-style: normal;
}

//@font-face {
//  font-family: $font-lato;
//  src: url("../fonts/Lato-LightItalic.svg");
//  src: url("../fonts/Lato-LightItalic.svg?#iefix") format("embedded-opentype"),
//  url("../fonts/Lato-LightItalic.woff2") format("woff2"),
//  url("../fonts/Lato-LightItalic.woff") format("woff"),
//  url("../fonts/Lato-LightItalic.ttf") format("truetype"),
//  url("../fonts/Lato-LightItalic.svg#example-font") format("svg");
//  font-weight: map-get($font-weight, light);
//  font-style: italic;
//}
@font-face {
  font-family: $font-lato;
  src: url("../fonts/Lato-Regular.svg");
  src: url("../fonts/Lato-Regular.svg?#iefix") format("embedded-opentype"),
    url("../fonts/Lato-Regular.woff2") format("woff2"),
    url("../fonts/Lato-Regular.woff") format("woff"),
    url("../fonts/Lato-Regular.ttf") format("truetype"),
    url("../fonts/Lato-Regular.svg#example-font") format("svg");
  font-weight: map-get($font-weight, normal);
  font-style: normal;
}

// ## Teko

@font-face {
  font-family: $font-teko;
  src: url("../fonts/elro/Teko-Light.woff2") format("woff2"),
    url("../fonts/elro/Teko-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-teko;
  src: url("../fonts/elro/Teko-Bold.woff2") format("woff2"),
    url("../fonts/elro/Teko-Bold.woff") format("woff");
  font-weight: map-get($font-weight, bold);
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-teko;
  src: url("../fonts/elro/Teko-Regular.woff2") format("woff2"),
    url("../fonts/elro/Teko-Regular.woff") format("woff");
  font-weight: map-get($font-weight, normal);
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-teko;
  src: url("../fonts/elro/Teko-SemiBold.woff2") format("woff2"),
    url("../fonts/elro/Teko-SemiBold.woff") format("woff");
  font-weight: map-get($font-weight, semi-bold);
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-teko;
  src: url("../fonts/elro/Teko-Medium.woff2") format("woff2"),
    url("../fonts/elro/Teko-Medium.woff") format("woff");
  font-weight: map-get($font-weight, medium);
  font-style: normal;
  font-display: swap;
}

// ## Montserrat

@font-face {
  font-family: $font-montserrat;
  src: url("../fonts/elro/Montserrat-Light.woff2") format("woff2"),
    url("../fonts/elro/Montserrat-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-montserrat;
  src: url("../fonts/elro/Montserrat-Italic.woff2") format("woff2"),
    url("../fonts/elro/Montserrat-Italic.woff") format("woff");
  font-weight: map-get($font-weight, normal);
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: $font-montserrat;
  src: url("../fonts/elro/Montserrat-Bold.woff2") format("woff2"),
    url("../fonts/elro/Montserrat-Bold.woff") format("woff");
  font-weight: map-get($font-weight, bold);
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-montserrat;
  src: url("../fonts/elro/Montserrat-Medium.woff2") format("woff2"),
    url("../fonts/elro/Montserrat-Medium.woff") format("woff");
  font-weight: map-get($font-weight, medium);
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-montserrat;
  src: url("../fonts/elro/Montserrat-Regular.woff2") format("woff2"),
    url("../fonts/elro/Montserrat-Regular.woff") format("woff");
  font-weight: map-get($font-weight, normal);
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-montserrat;
  src: url("../fonts/elro/Montserrat-SemiBold.woff2") format("woff2"),
    url("../fonts/elro/Montserrat-SemiBold.woff") format("woff");
  font-weight: map-get($font-weight, semi-bold);
  font-style: normal;
  font-display: swap;
}
