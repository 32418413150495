%link-hover-animation {
  &::after {
    content: "";
    position: absolute;
    bottom: px-to-em(-5px);
    left: 50%;
    width: 80%;
    height: 0;
    border-bottom: 1px solid;
    transform: translateX(-50%) scaleX(0);
    color: inherit;
    transition: $medium-transition;
  }
}

%link-hover-animation--hover {
  &::after {
    transform: translateX(-50%) scaleX(1);
  }
}

%link-hover-animation--hover-switch {
  @extend %link-hover-animation--hover;
}
