.product-main-description {
  .reinsurance-product {
    margin: 0 10px;
    width: unset;
    @include breakpoint($breakpoint-switch) {
      max-width: 1080px;
      margin: 0 auto;
    }
  }
}
