.store-locator {
  background-color: $color-cruise;

  &__heading {
    color: $color-black;
    font-family: $font-default;
    font-size: $size-xl-intermediate;
    font-weight: map-get($font-weight, light);
    margin-bottom: px-to-rem(10px);
  }

  &__input-group {
    $sub-base-class: ".input-field";
    width: 100%;
    @include breakpoint($breakpoint-switch) {
      width: px-to-em(450px);
    }

    #{$sub-base-class} {
      flex-direction: row;
      margin: 0 px-to-rem(15px);
      &__input {
        border-radius: px-to-rem(60px) 0 0 px-to-rem(60px);
      }
      &__button {
        background-color: $color-caribbean-green;
      }
    }
  }
}
