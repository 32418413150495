.product-view-description {
  &__tabs {
    @include breakpoint($breakpoint-switch) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  &__tab-link {
    @extend %link-hover-animation;

    position: relative;
    display: block;
    padding: px-to-em(18px) px-to-em(5px);
    background-color: $color-boticelli;
    text-align: center;

    @include breakpoint($breakpoint-switch) {
      $padding: px-to-em(25px);

      padding-right: $padding;
      padding-left: $padding;
    }

    &::after {
      bottom: 25%;
      width: 30%;
      max-width: px-to-em(64px);
    }
  }

  &__content {
    order: 2;
    width: 100%;
    padding: px-to-em(30px) 1em;
    background-color: $color-white;

    @include breakpoint($breakpoint-switch) {
      padding: px-to-em(30px);
    }
  }

  &__buttons-container {
    display: block;

    @include breakpoint($breakpoint-switch) {
      display: flex;
      flex-direction: row;
    }
  }

  &__button-wrapper {
    margin-top: px-to-em(20px);

    & + & {
      @include breakpoint($breakpoint-switch) {
        margin-left: px-to-em(16px);
      }
    }
  }

  &__button {
    width: px-to-em(180px);
  }

  &__datasheet-image {
    display: block;
    margin: auto;

    @include breakpoint($breakpoint-switch) {
      float: right;
      display: initial;
      margin-left: 2em;
    }
  }

  &__tab {
    order: 1;
    margin-top: 1px;
    margin-bottom: 0;
    font-size: 1em;
  }
}
