.blog-listing {
  padding: 0 1em;
  &__filter {
    @extend .gui-container;
    background-color: unset;
  }
  &__filter-container {
    padding: 0;
    width: 100%;
    > .form-input--inline.icon--angle-down {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 0.5em;
      @include breakpoint($breakpoint-switch) {
        width: fit-content;
      }
      &::before {
        bottom: 35%;
        right: px-to-rem(15px);
      }
    }
    .form-input__input {
      width: 100%;
      height: unset;
      line-height: 3em;
      @include breakpoint($breakpoint-switch) {
        width: fit-content;
      }
    }
  }
  &__grid {
    margin-top: px-to-rem(32px);
    @include breakpoint($breakpoint-switch) {
      margin-top: px-to-rem(50px);
    }
  }
}
