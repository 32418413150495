.input-select-countries {
  position: relative;

  &__label {
    @extend %base-text--default;
    font-family: $font-default;
    margin-bottom: 0.5em;
  }

  &__input {
    position: relative;
    padding: 0.75em 1em;
  }

  &__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 0.5em;
    font-size: px-to-rem(30px);
    font-weight: map-get($font-weight, "bold");
    color: $color-primary;
    cursor: pointer;
  }

  &__options {
    display: none;
    &--shown {
      background: $color-white;
      box-shadow: 2px 2px 6px 0 transparentize($color-black, 0.9);
      display: block;
      position: absolute;
      top: 110%;
      left: 0;
      right: 0;
      z-index: 4;
    }
  }

  &__input,
  &__options {
    font-family: $font-default;
    border: 2px solid $color-spring-wood;
    border-radius: px-to-em(25px);
    overflow: hidden;
  }

  &__option,
  &__placeholder {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }

  &__option {
    @extend %base-text--default;
    padding: 0.75em 1em;
    width: 100%;
    background: none;
    border: none;
    font-family: $font-default;
    &:hover {
      cursor: pointer;
      background: transparentize($color-primary, 0.7);
    }

    &--hidden {
      display: none;
    }
  }

  &__svg {
    border-radius: px-to-rem(5px);
  }
}
