.footer {
  $padding: px-to-em(20px);
  background: $color-footer-background-1;
  @include breakpoint($breakpoint-switch) {
    background-image: linear-gradient(
      90deg,
      $color-footer-background-1 50%,
      $color-footer-background-4 50%
    );
  }
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__background {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 55%);
    z-index: 2;
    background-color: $color-footer-background-2;
    @include breakpoint($breakpoint-switch) {
      clip-path: polygon(15% 0, 100% 0, 100% 100%, 40% 100%);
      max-width: 1080px;
      width: 100%;
      left: 10%;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 3;
    }

    &::before {
      clip-path: polygon(100% 55%, 25% 100%, 100% 100%);
      background-color: $color-footer-background-3;
    }

    &::after {
      clip-path: polygon(100% 70%, 50% 100%, 100% 100%);
      background-color: $color-footer-background-4;
    }
  }

  &__content,
  &__separator,
  &__mention {
    position: relative;
    z-index: 4;
  }

  &__content {
    padding: $padding;
  }

  &__pattern {
    display: none;
  }

  &__title {
    font-weight: map-get($font-weight, semi-bold);
    font-family: $font-secondary;
    color: $color-black;
    @extend %base-text--default;
    margin-bottom: px-to-em(5px);
  }

  &__social-link {
    border-color: $color-black;
    background-color: transparent;
    transition: all 500ms ease-in-out;

    svg {
      fill: $color-black;
    }

    &:hover {
      background-color: $color-black;

      svg {
        fill: $color-white;
      }
    }
  }

  &__separator {
    margin: 0;
    height: 1px;
    background-color: $color-ebb;
    width: 100%;
    border: none;
  }

  &__mention,
  &__link {
    color: $color-black;
    font-family: $font-default;
    font-weight: map-get($font-weight, light);
    text-wrap: avoid;
  }

  &__mention {
    width: 100%;
    border-top: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1em;
    padding: $padding;

    .gui-container {
      width: auto;
      margin: 0;
      @extend %base-text--small;
    }
  }

  &__post-footer-links ul {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  @include breakpoint($breakpoint-s) {
    &__background {
      clip-path: polygon(15% 0, 100% 0, 100% 100%, 40% 100%);
      &::before {
        clip-path: polygon(70% 0, 100% 0, 100% 100%, 50% 100%);
      }
      &::after {
        clip-path: polygon(80% 0, 100% 0, 100% 100%, 60% 100%);
      }
    }
  }
  @include breakpoint($breakpoint-s) {
    &__left {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 0.5em;
      width: 50%;
    }
    &__right {
      width: 40%;
    }
    &__content {
      max-width: px-to-rem($gui-container-max-width);
      display: flex;
      justify-content: space-between;
      gap: 1em;
      padding: $padding;
      width: 100%;
    }
    &__mention {
      max-width: px-to-rem($gui-container-max-width);
      padding: $padding;
      flex-direction: row;
    }
  }
}
