%main-title {
  font-size: px-to-em(25px, $base-font-size);
  color: $color-primary;
  margin: 0 auto;
  display: inline-block;
  font-weight: 900;
  line-height: px-to-em(30px, 25px);
}

%card-title {
  font-size: $font-size-l;
  font-weight: map-get($font-weight, bold);
  text-align: center;
  color: $color-primary;
}

%title-for-product-page-tabs {
  font-family: $font-secondary;
  font-size: px-to-rem(20px);
  font-weight: map-get($font-weight, "bold");
  padding: 0;
  text-transform: uppercase;
}
