.store-locator-store-card {
  font-family: $font-default;

  &__name {
    color: $color-primary;
  }

  &__distance {
    color: $color-black;
  }

  &__address,
  &__phone-number,
  &__phone-number-link,
  &__mail,
  &__mail-link {
    color: $color-black;
    font-weight: map-get($font-weight, light);
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }
}
