.product-tabs-table {
  &__title {
    margin-bottom: px-to-rem(20px);
  }

  &__instructions {
    margin: 0 0 px-to-rem(30px) 0;
  }

  &__slider {
    display: flex;
    flex-direction: row;
    gap: px-to-rem(20px);
    width: 100%;
    overflow: auto;
    margin-bottom: px-to-rem(58px);

    @include breakpoint($breakpoint-switch) {
      justify-content: center;
    }
  }

  &__slide {
    height: px-to-rem(300px);
    width: px-to-rem(300px);
    border: solid 2px $color-alto;
    padding: px-to-rem(10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;

    &--selected {
      border-color: $color-primary;
    }
  }

  &__slide-title {
    font-weight: map-get($font-weight, semi-bold);
    font-size: px-to-rem(20px);
    font-family: $font-secondary;
    align-self: flex-start;
    margin-bottom: 0;
  }

  &__image {
    flex-grow: 1;
    object-fit: contain;
  }

  &__table-wrapper {
    width: 100%;
    overflow: auto;
    display: none;

    &--selected {
      display: block;

      .header-cell {
        width: fit-content !important;
        min-width: fit-content;
        cursor: pointer;

        &::after {
          right: -2em !important;
        }
      }
    }
  }

  table {
    border: none;
    font-weight: map-get($font-weight, medium);
  }

  .table-wrapper p {
    margin: 0;
  }

  table tr:nth-child(-n + 2) {
    /* Styles for the first two tr */
    font-weight: map-get($font-weight, semi-bold);
    font-size: px-to-rem(18px);
  }

  table tr:not(:nth-child(-n + 2)) {
    /* Styles for all tr except the first two */
    position: relative;
    transform: scale(1);

    &::after {
      position: absolute;
      top: -1px;
      left: 0;
      bottom: 1px;
      width: 1px;
      content: "";
      background-color: $color-primary;
    }
  }

  .header-row {
    border-top: solid 3px white;
    height: px-to-rem(70px);
    display: table-row;
    width: 100%;

    @include breakpoint($breakpoint-xxs) {
      height: px-to-rem(35px);
    }

    @include breakpoint($breakpoint-full-hd) {
      display: table-row;
    }
  }

  td {
    border: solid px-to-rem(1px) white;
    background-color: $color-alto;
    min-width: px-to-rem(200px);
  }

  table tr:not(.header-row):not(:nth-child(1)) {
    /* Styles for all the tr except the first one and thoses which are header row */
    height: auto !important;

    td {
      height: auto !important;
      padding: px-to-rem(10px);
    }
  }

  .header-cell {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: px-to-rem(1px);
    bottom: 0;
    left: px-to-rem(-2px);
    width: 86vw;
    min-height: px-to-rem(35px);
    background-color: transparent;
    font-size: px-to-rem(20px);
    font-family: $font-secondary;
    font-weight: map-get($font-weight, bold);
    padding-left: px-to-rem(15px);
    border-bottom: none;
    border-right: none;

    @include breakpoint($breakpoint-xxs) {
      display: table-cell;
      bottom: unset;
      width: 88vw;
    }

    @include breakpoint($breakpoint-xs) {
      width: 90vw;
    }

    @include breakpoint($breakpoint-s) {
      width: 94vw;
    }

    @include breakpoint($breakpoint-xl) {
      width: 96vw;
    }

    @include breakpoint($breakpoint-full-hd) {
      top: px-to-rem(4px);
      left: px-to-rem(-1px);
      position: relative;
      width: 100%;
    }

    &::before {
      position: absolute;
      height: 90%;
      width: px-to-rem(10px);
      content: "";
      background-color: $color-primary;
      left: px-to-rem(1px);
      top: px-to-rem(-4px);
    }

    &::after {
      position: absolute;
      right: 0;
      top: 0;
      content: "▲";
    }

    &--collapsed {
      display: table-cell;

      &::after {
        content: "▼";
      }
    }
  }

  pre {
    margin: auto;
    font-size: px-to-rem(16px);
    font-family: $font-default;
  }
}
