.accessory-card {
  border: solid 2px $color-alto-bis;
  padding: px-to-rem(20px);
  max-width: px-to-em(320px);
  min-width: 100%;
  width: 100%;
  height: 100%;

  &__header,
  &__content {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  &__header {
    align-items: center;
  }

  &__product-name {
    font-family: $font-secondary;
    font-size: px-to-rem(20px);
    font-weight: map-get($font-weight, "medium");
    margin-bottom: 0;
  }

  &__associated-products-group {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
  }

  &__associated-product-name {
    padding: 0.25em 0.5em;
    border-radius: px-to-rem(20px);
    border: 1px solid $color-ghost-grey;
    width: fit-content;
  }

  &__description {
    strong {
      font-weight: map-get($font-weight, "semi-bold");
    }
    p {
      margin-bottom: px-to-em(10px);
    }
  }
}
