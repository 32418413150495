.breadcrumbs {
  color: $color-default;
  &__container {
    background-color: unset;
  }

  &__item {
    @extend %base-text--small;

    &::after {
      content: "//";
      margin-left: px-to-rem(4px);
      font-weight: map-get($font-weight, normal);
    }
  }
}
