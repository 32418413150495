.page-not-found {
  text-align: center;
  padding-top: px-to-em(40px);

  &__404 {
    height: px-to-em(173px);
    width: px-to-em(418px);
    max-width: 100%;
    .b {
      fill: $color-white;
    }
  }
}
