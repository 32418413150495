$base-class: ".sticky-button";

#{$base-class} {
  position: fixed;
  z-index: $z-index-sticky-button;
  display: inline-block;
  width: px-to-em(56px);

  @include breakpoint($breakpoint-m) {
    width: px-to-em(64px);
  }

  &:hover {
    #{$base-class} {
      &__svg-animated {
        transform: translateY(-5px);
      }
    }
  }

  &__svg-background {
    fill: $color-primary;
  }

  &__svg-color {
    fill: $color-white;
  }

  &__svg-animated {
    transition: transform $default-transition-duration;
  }
}
