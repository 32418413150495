$base-class: ".input-field";
#{$base-class} {
  $iconButtonWidth: px-to-em(65px);
  $smallHeight: px-to-em(50px);
  $smallIconButtonWidth: px-to-em(55px);

  position: relative;

  &__wrapper {
    flex-grow: 1;
    position: relative;
  }

  &__input {
    height: 100%;
    width: 100%;
    border: none;
    background-color: $color-white;

    &--placeholder-italic {
      &:placeholder-shown {
        font-style: italic;
        color: $color-nobel;
      }
    }
  }

  &__button {
    border: none;
    color: $color-white;
    font-weight: map-get($font-weight, bold);
    font-size: px-to-em(16px);
    cursor: pointer;
    transition: background-color $default-transition-duration;
    flex-shrink: 0;

    &--with-icon {
      display: flex;
      align-items: center;
      font-size: px-to-em(30px);

      &::before {
        @extend %icon;
        @extend %icon--magnifier;
      }
    }
  }

  &__error[generated="true"] {
    position: absolute;
    left: 20px;
    top: 100%;
    width: 100%;
    color: $color-error;
    font-size: px-to-em(14px);
  }
}
