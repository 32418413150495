.quote {
  &__author {
    font-family: $font-secondary;
    font-weight: map-get($font-weight, "semi-bold");
  }

  &__image {
    @include breakpoint($breakpoint-s) {
      width: 25%;
      height: calc(100% - 40px);
      object-fit: cover;
    }
  }
}
