.lang-switcher {
  &__label {
    border: none;
    outline: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    font-family: $font-lato;
    font-size: px-to-rem(14px);
    font-weight: map-get($font-weight, regular);
    text-wrap: none;
    color: $color-white;
  }

  &__list {
    display: none;

    &--active {
      display: block;
    }
  }

  &__icon {
    border-radius: px-to-rem(5px);
  }
}
