$base-class: ".news-card";

#{$base-class} {
  position: relative;
  box-sizing: border-box;

  &--centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &--overlay {
    width: 100%;
    height: px-to-em(245px);
    position: relative;
    @include breakpoint($breakpoint-tablet) {
      height: px-to-em(300px);
    }
    @include breakpoint($breakpoint-xl) {
      width: px-to-em(510px);
      height: px-to-em(420px);
    }

    #{$base-class}__text {
      font-size: px-to-rem($font-size-s);
    }
  }

  &--swap {
    display: flex;

    @include breakpoint($breakpoint-tablet) {
      flex-direction: column;
      border: 0;
      height: 100%;
      width: px-to-em(330px);
    }

    #{$base-class}__text {
      font-size: px-to-rem($font-size-m);
      line-height: px-to-em(20px, 16px);
      margin-bottom: 0;
      max-height: px-to-rem(60px);
      overflow: hidden;
      @include breakpoint($breakpoint-tablet) {
        max-height: none;
      }
    }
  }

  &--has-separator {
    border-bottom: 1px solid $color-athens-gray;
  }

  &__img-container {
    width: px-to-em(125px);
    min-width: px-to-em(125px);
    height: px-to-em(105px);
    @include breakpoint($breakpoint-tablet) {
      width: px-to-em(330px);
      height: px-to-em(275px);
      margin-bottom: px-to-em($gap-xs);
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    font-family: $font-secondary;
    color: $color-white;
    padding: 0 px-to-em($gap-l);
    transition: background-color ease 200ms;

    @include breakpoint($breakpoint-tablet) {
      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    @include breakpoint($breakpoint-xl) {
      padding: 0 px-to-em($gap-xxxl);
    }
  }

  &__title {
    margin-bottom: 0;
  }

  &__text {
    font-weight: map-get($font-weight, light);
  }

  &__btn {
    display: none;
    @include breakpoint($breakpoint-tablet) {
      display: inline-flex;
      width: fit-content;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;

    @include breakpoint($breakpoint-tablet) {
      display: block;
    }
  }

  &__content {
    flex-grow: 1;
    padding: px-to-em($gap-xs);

    @include breakpoint($breakpoint-tablet) {
      padding: 0 0 px-to-em($gap-xs) 0;
    }
  }

  &__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
