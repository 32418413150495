body {
  &:not(.page-layout-light-page) {
    .page-main {
      padding-bottom: px-to-em(95px);
    }
  }

  .widget-category-preview,
  .widget-tabs-slider,
  .widget-banner-brands {
    margin-top: px-to-em(91px);
  }

  .widget-title-text {
    margin-top: 3em;
  }
}
