$base-class: ".menu-mobile";
$menu-component-class: ".menu";
#{$base-class} {
  pointer-events: none;

  &:has(#{$base-class}__search-block--active) {
    background-color: rgba($color-black, 0.7);
    pointer-events: unset;
  }

  &__head {
    background: $color-white;
    border-bottom: 1px solid transparentize($color-ghost-grey, 0);
    &:has(#{$base-class}__burger--active) {
      border-bottom: none;
    }
  }

  &__burger {
    background-color: transparent;

    &::before,
    &::after {
      background-color: $color-primary;
    }

    &--active {
      font-size: 1rem;
      &::before,
      &::after {
        height: px-to-rem(3px);
      }
    }
  }

  &__search-button {
    border-radius: 50%;
    height: px-to-em(40px, 20px);
    width: px-to-em(40px, 20px);
    background-color: $color-primary;

    &--active {
      color: $color-white;
      background-color: $color-primary;
    }
  }

  &__search-block {
    padding: px-to-em(15px) px-to-em(10px) px-to-em(15px) px-to-em(10px);
    background-color: $color-black;

    .input-field {
      flex-direction: row;
      height: px-to-em(50px);
      padding: 0;

      &__input {
        &:active,
        &:focus {
          outline: unset;
        }

        &::placeholder {
          font-style: italic;
          font-family: $font-default;
        }

        @include breakpoint($breakpoint-m) {
          height: 100%;
        }
      }

      &__button {
        color: $color-primary;
        height: 100%;
        padding: 0;
        margin-top: 0;
        margin-right: 1em;
        box-sizing: border-box;
        width: fit-content;
      }
    }
  }

  &__separator-block {
    width: 100%;
    background: $color-white;
    flex-grow: 1;
  }

  .pre-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 1em;

    .lang-switcher {
      height: 2.5em;
      display: flex;
    }
  }

  // mobile menu styles
  &__first-strate {
    display: flex;
    flex-direction: column;
  }

  &__item {
    height: unset;
    padding: 1em;
    font-weight: map-get($font-weight, "medium");
  }

  &__item,
  &__submenu-item,
  &__third-menu-item,
  &__fourth-menu-item {
    gap: 0.5em;
    background: $color-white;
    color: $color-black;
    font-family: $font-teko;
    border-top: 1px solid transparentize($color-ghost-grey, 0);

    &#{$base-class}__opener {
      color: $color-primary;
      font-family: $font-default;
    }
  }

  &__second-strate,
  &__third-strate,
  &__fourth-strate {
    height: 0;
    opacity: 0;
    overflow: hidden;

    &--active {
      color: $color-black !important;
      > #{$base-class}__chevron {
        transform: rotate(180deg);
      }
    }

    &--active:not(#{$base-class}__opener) {
      height: auto;
      opacity: 1;
      overflow: visible;

      #{$base-class}__submenu-header {
        display: none;
      }
    }
  }

  #{$menu-component-class} {
    &__submenu-list {
      background: $color-white;
      flex-wrap: wrap;
      flex-direction: row;
      border-top: 1px solid transparentize($color-ghost-grey, 0.7);
      gap: 2em 1em;
      padding: 2em 0;

      &::before {
        display: none;
      }

      #{$menu-component-class} {
        &__n2-item {
          width: 48%;
          &:nth-child(2n) {
            border-left: 1px solid transparentize($color-ghost-grey, 0.7);
          }
        }

        &__item-inner {
          flex-direction: column;
          height: auto;
          gap: 1em;
        }

        &__item-image {
          height: px-to-rem(160px);
          display: block;
        }

        &__item-text {
          font-family: $font-teko;
          font-size: px-to-rem(20px);
          color: $color-black;
          &::before {
            content: "▸";
            padding-right: px-to-rem(5px);
          }
        }
      }
    }

    &__group-buttons {
      background: $color-white;
      display: flex;
      flex-direction: column;
      row-gap: 1em;
      padding: 0 2em 2em 2em;
    }

    &__product-menu-inner-button {
      width: fit-content;
    }
  }

  .input-field {
    padding: 0;
    flex-direction: row;

    &__button {
      position: absolute;
    }
  }
}
