.product-card {
  padding: 0;
  max-height: px-to-em(450px);
  width: 100%;

  &__title {
    font-family: $font-secondary;
    font-size: px-to-rem($font-size-xl);
    font-weight: map-get($font-weight, medium);
    text-align: center;
    color: $color-black;
    margin-bottom: 0;
    @include breakpoint($breakpoint-tablet) {
      margin-bottom: px-to-em($gap-xxxs);
    }
  }

  &__text {
    font-family: $font-default;
    font-size: px-to-rem($font-size-m);
    font-weight: map-get($font-weight, normal);
    margin-bottom: px-to-rem(20px);
    @include breakpoint($breakpoint-tablet) {
      margin-bottom: px-to-rem(10px);
    }
  }

  &__button {
    border-radius: px-to-rem(35px);
  }

  &__overlay {
    position: relative;
    opacity: 1;
    background-color: unset;
    @include breakpoint($breakpoint-tablet) {
      background-color: transparentize($color-primary, 0.8);
      position: absolute;
      opacity: 0;
    }
  }
}
