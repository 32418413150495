.profit-calculator-line {
  margin-top: 2em;

  &__content {
    display: flex;
    flex-direction: row;
    width: 100%;

    &--important {
      justify-content: center;

      @include breakpoint($breakpoint-switch) {
        justify-content: flex-start;
      }
    }
  }

  &__arrow {
    margin-right: px-to-rem(8px);
  }

  &__main-line-wrapper {
    display: flex;
    flex-direction: column;

    @include breakpoint($breakpoint-switch) {
      width: 100%;
      flex-direction: row;
    }
  }

  &__title {
    font-weight: map-get($font-weight, bold);
    flex-shrink: 0;

    &--important {
      color: $color-viridian;
      font-size: px-to-rem(24px);
    }
  }

  &__description {
    font-size: px-to-rem(14px);
    color: $color-gray;

    @include breakpoint($breakpoint-switch) {
      margin-left: 0;
    }

    &--important {
      text-align: center;

      @include breakpoint($breakpoint-switch) {
        text-align: left;
      }
    }
  }

  &__filler {
    display: none;

    @include breakpoint($breakpoint-switch) {
      display: block;
      flex: 1;
      border-top: 1px solid $color-alto;
      margin: auto 1em;
    }
  }

  &__amount-wrapper {
    display: flex;
    flex-shrink: 0;

    &--important {
      display: none;

      @include breakpoint($breakpoint-switch) {
        display: flex;
      }
    }
  }

  &__amount {
    font-weight: map-get($font-weight, bold);
    color: $color-viridian;

    &--important {
      visibility: hidden;

      @include breakpoint($breakpoint-switch) {
        font-size: px-to-rem(24px);
        visibility: visible;
      }
    }
  }

  &__hint-element {
    height: px-to-rem(17px);
    width: px-to-rem(17px);
    border-radius: px-to-rem(8px);
    background-color: $color-alto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: px-to-rem(8px);

    &::before {
      font-size: px-to-rem(10px);
    }

    &--important {
      visibility: hidden;
      width: 0;
    }
  }

  &__hint-title {
    font-weight: map-get($font-weight, bold);
  }

  &__important-amount {
    display: none;

    &--important {
      display: block;
      margin: px-to-rem(10px) auto 0;
      text-align: center;
      font-weight: map-get($font-weight, bold);
      color: $color-viridian;
      font-size: px-to-rem(24px);

      @include breakpoint($breakpoint-switch) {
        display: none;
      }
    }
  }
}
