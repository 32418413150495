.store-locator-large {
  position: relative;
  height: px-to-em(672px);

  &__stores {
    position: absolute;
    z-index: 401;
    top: 0;
    right: 50%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: space-between;
    width: 100%;
    max-width: min(50vw, #{$gui-container-max-width * 0.5});
    padding: px-to-em(30px) px-to-em(20px) px-to-em(20px);
    background-color: $color-white;
  }

  &__stores-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__stores-list {
    flex: 1;
    overflow-y: auto;
    margin-top: px-to-em(28px);
  }

  &__stores-item {
    $shadow-offset-x: 3px;

    margin: $shadow-offset-x;
    padding: px-to-em(24px) px-to-em(20px);
    box-shadow: 0 $shadow-offset-x 6px rgba($color-black, 0.1);
    transition: box-shadow $default-transition-duration ease-in-out;

    &:hover {
      box-shadow: 0 $shadow-offset-x 6px rgba($color-black, 0.2);
    }

    & + & {
      margin-top: px-to-em(28px);
    }

    &--move {
      transition: transform 500ms;
    }
  }

  .main-title {
    margin-bottom: 0;
    padding-left: px-to-em(16px);
    text-align: left;
  }
}

.store-locator-small {
  &__map-container {
    position: relative;
    height: 50vh;
  }

  &__stores-list {
    padding: px-to-em(20px) px-to-em(10px);
    box-shadow: 0 3px 6px #0000001a;
  }

  &__stores-item {
    width: px-to-em(280px);
  }

  .store-locator-around-me {
    position: absolute;
    z-index: 1000;
    bottom: 0.625em;
    left: 50%;
    transform: translateX(-50%);
  }
}

.leaflet-popup-content-wrapper {
  border-radius: 20px !important;
}

.leaflet-popup-tip-container {
  display: none;
}

.leaflet-popup-close-button {
  top: 10px !important;
  right: 10px !important;
  display: flex;
  padding: 0 !important;
  font-size: 0 !important;

  &::before {
    @extend %icon;
    @extend %icon--close;

    font-size: 16px;
  }
}

.leaflet-control-zoom {
  display: flex;
  flex-direction: column;
  row-gap: 0.3em;
  border: none !important;
  &-in,
  &-out {
    height: px-to-rem(46px) !important;
    line-height: px-to-rem(42px) !important;
    width: px-to-rem(46px) !important;
    border-radius: px-to-rem(10px) !important;
    box-shadow: 0 3px 6px #00000029;
  }
}
