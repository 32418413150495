// # Colors
//
// Color variables SHOULD be prefixed with 'color-' to identify them easier and to be able to
// filter them in your favorite IDE/editor's auto-completion when typing.
//
// You can use tools like [Name that color](http://chir.ag/projects/name-that-color) to create
// color names.
// When doing so, you can add a second prefix to group colors and order them from lightest to darkest:
//
// ```scss
// $color-red: #f00;
// $color-red-monza: #D20434;
//
// $color-gray-gallery: #efefef;
// $color-gray: #808080;
// $color-gray-mine-shaft: #333;
// ```

// ## Color list
$color-blue: blue;
$color-yellow: yellow;
$color-amber: #ffbf00;
$color-blue-zodiac: #122359;
$color-blue-haze: #c0c7db;
$color-east-bay: #3e4d7e;
$color-ship-cove: #6c7eb9;
$color-nobel: #b7b7b7;
$color-cadet-blue: #abb1c4;
$color-monza: #e30613;
$color-thunderbird: #c51919;
$color-athens-gray: #e6e8ee;
$color-tuatara: #3c3c3b;
$color-san-marino-blue: #465eaa;
$color-ghost-grey: #cdcfd8;
$color-boticelli: #d6dbeb;
$color-alto: #d0d0d0;
$color-alto-bis: #dedede;
$color-mercury: #e5e5e5;
$color-silver-chalice: #afafaf;
$color-ebb: #e6e1de;
$color-wild-sand: #f4f4f4;
$color-friar-gray: #7e7e7a;
$color-cruise: #b4efe0;
$color-honey-flower: #681e79;
$color-picton-blue: #42bee3;
$color-pelorus: #38a2c1;
$color-alizarin-crimson: #e53517;
$color-cruise: #b4efe0;
$color-polar: #e6f5fa;
$color-link-water: #d3ebf5;
$color-humming-bird: #c6ecf7;
$color-bermuda: #8bddd9;
$color-spring-wood: #e6e1de;
$color-athens-gray-2: #f7f7f8;
$color-caribbean-green: #09cb99;
$color-viridian: #439675;
$color-gray: #919191;
