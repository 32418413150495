$base-class: ".sticky-button";

#{$base-class} {
  background-color: $color-primary;
  border-top-left-radius: 2em;
  border-bottom-left-radius: 2em;
  right: px-to-rem(-5px);
  bottom: 4em;
  @include breakpoint($breakpoint-m) {
    bottom: 3em;
  }

  &__svg-border,
  &__svg-background {
    fill: none;
  }
}
