$base-class: ".product-images-side-texts";

#{$base-class} {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 !important;
  border-left: 1px solid $color-picton-blue;
  margin: 0 px-to-rem(10px);
  @include breakpoint($breakpoint-switch) {
    margin: 0;
  }

  &__wrapper {
    display: none;
    flex-direction: column;
    gap: 1em;
    margin-top: px-to-rem(20px);
    @include breakpoint($breakpoint-s) {
      flex-direction: row;
    }

    &--active {
      display: flex;
    }
  }

  &__images-container,
  &__texts-container {
    width: 50%;
  }

  &__texts-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;
    padding-right: px-to-rem(10px);
    @include breakpoint($breakpoint-s) {
      padding-right: 0;
    }
  }

  &__text-container {
    padding: 0 px-to-rem(10px);
    @include breakpoint($breakpoint-s) {
      padding: 0 0 0 px-to-rem(15px);
    }
  }

  &__title {
    font-family: $font-secondary;
    font-size: px-to-rem(20px);
    font-weight: map-get($font-weight, "bold");
    margin-bottom: unset;
    position: relative;
    padding: 0 px-to-rem(15px) 0 px-to-rem(30px);
    text-transform: uppercase;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      width: px-to-rem(10px);
      height: 100%;
      background-color: $color-primary;
    }
  }

  &__title-button-dropdown {
    position: absolute;
    right: 0;
    top: 20%;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    background: $color-black;
    height: px-to-rem(10px);
    width: px-to-rem(10px);
    margin-right: px-to-rem(10px);
    cursor: pointer;
    &--active {
      transform: rotate(180deg);
    }
  }

  &__label,
  &__text {
    font-family: $font-default;
    font-size: px-to-rem(16px);
  }

  &__label {
    font-weight: map-get($font-weight, "bold");
  }

  &__text {
    font-weight: map-get($font-weight, "normal");
  }

  &__images-container {
    flex: 1;
    width: 100%;
    padding: 0 px-to-rem(10px);
    @include breakpoint($breakpoint-s) {
      padding: 0;
    }

    #{$base-class}__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--multiples-images {
      display: flex;
      flex-direction: column;
      gap: px-to-rem(10px);
      @include breakpoint($breakpoint-s) {
        gap: px-to-rem(20px);
      }
      #{$base-class}__image {
        width: 100%;
        object-fit: cover;
        min-height: px-to-rem(250px);
        max-height: px-to-rem(450px);
      }
    }
  }
}
