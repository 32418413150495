.tabs-slider {
  position: relative;
  margin-bottom: px-to-em(40px);

  @include breakpoint($breakpoint-switch) {
    margin-bottom: px-to-em(100px);
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__tabs-list {
    display: flex;
    justify-content: center;
    margin-bottom: px-to-em(30px);
  }

  &__select-container {
    width: 90%;
    display: block;
    position: relative;

    @include breakpoint($breakpoint-switch) {
      display: none;
    }
  }

  &__select {
    width: 100%;
    height: px-to-em(50px);
    font-size: 1em;
  }

  &__label {
    @extend .banner-text-tabs__label;
    display: none;
    background: transparent;
    border: none;
    cursor: pointer;

    @include breakpoint($breakpoint-switch) {
      display: block;
    }
  }

  &__tabs-blocks {
    width: 100%;
    position: relative;
  }

  &__tab-content {
    display: none;
    width: 100%;
    opacity: 0;
    flex-direction: column;

    @include breakpoint($breakpoint-xxs) {
      top: 10em;
    }

    @include breakpoint($breakpoint-switch) {
      flex-direction: row;
      height: px-to-rem(500px);
      top: 8.25em;
    }

    &--active {
      opacity: 1;
      display: flex;
    }
  }

  &__slider {
    width: 100%;
    overflow: hidden;
    flex-grow: 1;
    padding: px-to-em(22px) px-to-em(30px) px-to-em(22px) px-to-em(10px);
    background-color: $color-wild-sand;

    @include breakpoint($breakpoint-xs) {
      padding: px-to-em(22px) px-to-em(68px) px-to-em(22px) px-to-em(20px);
    }

    @include breakpoint($breakpoint-switch) {
      width: 50%;
      height: 100%;
      padding: px-to-em(60px) px-to-em(110px) px-to-em(70px) px-to-em(55px);
    }
  }

  &__slide {
    background: $color-white;
    padding: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    color: $color-primary;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    height: auto;
  }

  &__product {
    width: 100%;
    display: flex;
    margin-bottom: 1em;
    text-align: center;

    @include breakpoint($breakpoint-s) {
      text-align: left;
    }
  }

  &__product-img {
    max-width: px-to-em(150px);
    object-fit: cover;
    margin: 0 auto;
  }

  &__product-name {
    font-family: $font-default;
    font-size: px-to-rem(18px);

    @include breakpoint($breakpoint-switch) {
      font-size: px-to-rem(20px);
    }
  }

  &__product-description {
    font-size: px-to-rem(14px);
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 4;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  &__quote-author {
    font-size: px-to-rem(14px);
    font-weight: map-get($font-weight, bold);
    @include breakpoint($breakpoint-switch) {
      font-size: 1em;
    }
  }

  &__quote-text {
    font-size: px-to-rem(14px);
    font-weight: map-get($font-weight, light);
    @include breakpoint($breakpoint-switch) {
      font-size: 1em;
    }
  }

  .swiper-navigation {
    display: none;
    @include breakpoint($breakpoint-switch) {
      display: block;
    }

    &--prev {
      left: 0.5em;
    }

    &--next {
      right: 1.7em;
    }
  }
}
